import React, { useState, useEffect, useRef } from "react";
import Autocomplete from "react-google-autocomplete";
import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Form,
  Dropdown,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";

import request from "services/request";
import { useLoading } from "contexts/LoadingContextManagement";
import {
  RESTAURANTS_TYPES_LIST,
  TIME_ZONES,
  DEFAULT_ERROR_MESSAGE,
} from "common/constants";
import { useOnDropAccepted } from 'common/utils.ts';

import JPEG_ICON from "assets/images/icon/jpeg.svg";
import CLOSE_ICON from "assets/images/icon/DELETE.svg";
import EYE_ICON from "assets/images/icon/eye.svg";
import EditIcon from "assets/images/icon/edit-icon.svg";

import { useUserData } from "contexts/AuthContextManagement";

import '../index.scss';

function AddRestaurantsModal(props) {
  const { t } = useTranslation();
  const { restaurantData, onHide, show } = props;
  const { isLabo } = useUserData();

  const intialState = {
    name: "",
    address: "",
    country: "",
    zip_code: "",
    siret_number: "",
    latitude: "",
    longitude: "",
    timezone: "",
    type: "",
    city: "",
    state: "",
    ...(isLabo && {is_labo: false})
  };

  const intialInvoiceState = {
    name: "",
    contact_name: "",
    email: "",
    phone_number: "",
    address: "",
    siret_number: "",
  };
  const [state, setState] = useState(intialState);
  const [showInvoiceContainer, setShowInvoiceContainer] = useState(false);
  const [restaurantInvoice, setRestaurantInvoice] = useState(intialInvoiceState);

  const googleTimezoneUrl =
    "https://maps.googleapis.com/maps/api/timezone/json";

  const [files, setFiles] = useState([]);
  const { setLoading, setError } = useLoading();

  const [fd, setFd] = useState({ allergens: [] });
  const [selectedRegion, setSelectedRegion] = useState("England");
  const [selectedItem, setSelectedItem] = useState("");
  const [editableVAT, setEditableVAT] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const prevRestaurantRef = useRef(null);

  const fakeDropdownData = {
    France: [
      { name: "Eggs", vat: "5.5%" },
      { name: "Oils & Butter", vat: "5.5%" },
      { name: "Beverages (sugary drinks & sodas)", vat: "20%" },
      { name: "Beverages (essential)", vat: "5.5%" },
      { name: "Laits", vat: "5.5%" },
      { name: "Fruits & Vegetables", vat: "5.5%" },
      { name: "Meat & Fish", vat: "5.5%" },
      { name: "Pasta", vat: "5.5%" },
      { name: "Rice & Legumes", vat: "5.5%" },
      { name: "Sauces", vat: "5.5%" },
      { name: "Cheese", vat: "5.5%" },
      { name: "Bread & Bakery", vat: "5.5%" },
      { name: "Packaging", vat: "5.5%" },
      { name: "Cleaning", vat: "20%" },
      { name: "Others", vat: "20%" }
    ],
    England: [
      { name: "Eggs", vat: "0%" },
      { name: "Oils & Butter", vat: "0%" },
      { name: "Beverages (sugary drinks & sodas)", vat: "20%" },
      { name: "Beverages (essential)", vat: "0%" },
      { name: "Laits", vat: "0%" },
      { name: "Fruits & Vegetables", vat: "0%" },
      { name: "Meat & Fish", vat: "0%" },
      { name: "Pasta", vat: "0%" },
      { name: "Rice & Legumes", vat: "0%" },
      { name: "Sauces", vat: "0%" },
      { name: "Cheese", vat: "0%" },
      { name: "Bread & Bakery", vat: "0%" },
      { name: "Packaging", vat: "0%" },
      { name: "Cleaning", vat: "20%" },
      { name: "Others", vat: "20%" }
    ],
    // Other: [
    //   { name: "Rice", vat: "5%" },
    //   { name: "Pasta", vat: "10%" },
    //   { name: "Olive Oil", vat: "15%" },
    //   { name: "Sugar", vat: "5%" },
    //   { name: "Salt", vat: "5%" },
    //   { name: "Pepper", vat: "5%" },
    //   { name: "Garlic", vat: "5%" }
    // ],
  };
  
  const handleDropdownSelect = (item) => {
    setSelectedItem(item);
    setEditableVAT(item.vat);
    setIsEditing(false);
  };

  const handleVATChange = (e) => {
    setEditableVAT(e.target.value); // Update VAT value as user types
  };

  const handleSaveVAT = () => {
    setIsEditing(false);
  };
  
  const handleCheckboxChange = (region) => {
    setSelectedRegion(region);
    setSelectedItem("");
  };

  const onDropAccepted = useOnDropAccepted(setFiles, setError, t);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/jpg, image/jpeg, image/png",
    maxFiles: 1,
    onDropAccepted
  });

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const onRemoveFile = (file, i) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };
  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="..." />
      </div>
    </div>
  ));

  const getRestaurantInvoice = async(restaurantId) => {
    try {
      const result = await request.get(`restaurant-invoices/${restaurantId}`);
      setRestaurantInvoice(result)
      prevRestaurantRef.current = result
    }catch(error){
      setRestaurantInvoice(null)
    }
  }

  useEffect(() => {
    if (restaurantData != null) {
      setState(restaurantData);
      getRestaurantInvoice(restaurantData.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    // Prevent default behavior
    event.preventDefault();
    var restaurant;
    if (restaurantData != null) {
      if((JSON.stringify(restaurantInvoice) !== JSON.stringify(prevRestaurantRef.current)) && restaurantInvoice) {
        delete restaurantInvoice.id
        await request.patch(`/restaurant-invoices/${restaurantData.id}`, {...restaurantInvoice, restaurant_id: restaurantData.id});
      }
      restaurant = props.updateRestaurant(state);
    } else {
      restaurant = props.saverestaurant(state);
    }

    if (acceptedFiles.length) {
      fileUpload(await restaurant);
    }
    props.reloadRestaurant();
  };

  const fileUpload = async (restaurant, create = true) => {
    if (!acceptedFiles.length) {
      setFd({ allergens: [] });
      setLoading(false);
      return;
    }
    try {
      let formData = new FormData();
      formData.append("image", acceptedFiles[0]);

      await request.patchFile(
        `/restaurants/upload-image/${restaurant.id}`,
        formData
      );

      setLoading(false);
      setFd({ allergens: [] });
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    } finally {
    }
  };

  const handleTypeSelect = (val) => {
    setState({ ...state, type: val });
  };

  const handleTimeZoneSelect = (val) => {
    setState({ ...state, timezone: val });
  };

  const onPlaceSelect = (place, dataField = 'restaurant') => {
    if(dataField === 'invoice') {
      setRestaurantInvoice((prevState) => ({
        ...prevState,
        address: place.formatted_address,
      }));
      return
    }
    var filtered_array = place.address_components.filter(function (
      address_component
    ) {
      return address_component.types.includes("country");
    });
    //country
    var country = filtered_array.length ? filtered_array[0].long_name : "";

    //city
    let matches = place.address_components.filter((address_component) =>
      ["locality", "colloquial_area", "administrative_area_level_2"].some(
        (word) => ~address_component.types.indexOf(word)
      )
    );
    let city = "";
    if (!matches || !matches.length) {
      console.log("Could not resolve city name.");
    } else {
      city = matches[0].short_name;
    }

    let stateMatches = place.address_components.filter((address_component) =>
      ["administrative_area_level_1"].some(
        (word) => ~address_component.types.indexOf(word)
      )
    );
    let countryState = "";
    if (!stateMatches || !stateMatches.length) {
      console.log("Could not resolve countryState name.");
    } else {
      countryState = stateMatches[0].short_name;
    }
    setState((prevState) => ({
      ...prevState,
      address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      country: country,
      city: city,
      state: countryState,
    }));

    getRestaurantTimezone(
      place.geometry.location.lat(),
      place.geometry.location.lng()
    );
  };

  const getRestaurantTimezone = async (latitude, longitude) => {
    try {
      const url = `${googleTimezoneUrl}?location=${latitude}%2C${longitude}&timestamp=0&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY} `;
      const result = await fetch(`${url}`, {
        method: "GET",
      });
      if (result.status !== 200) {
        const response = await result.json();
        return;
      }
      const response = await result.json();
      setState((prevState) => ({
        ...prevState,
        timezone: response.timeZoneId,
      }));
    } catch (error) {}
    return "";
  };

  return (
    <div
      {...props}
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-restaurant"
    >
      <div className="add-restaurant-heading">
        {restaurantData ? t("EditRestaurant") : t("AddRestaurant")}
        <div className="order-back back" onClick={onHide}>
          {"<"} {t("Back")}
        </div>
      </div>

      <Modal.Body className="add-restaurants-modal-body">
        <Container>
          <Form className="ps-0 pe-0" onSubmit={handleSubmit}>
            <Form.Group className="mb-2">
              <Row>
                <Col lg={6}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("RestaurantName")}
                    </span>
                    <Form.Control
                      type="text"
                      className="restaurant-input-field"
                      required
                      onChange={(event) =>
                        setState({ ...state, name: event.target.value })
                      }
                      defaultValue={restaurantData ? restaurantData.name : ""}
                    />
                  </Row>
                </Col>
                <Col lg={{ offset: 1, span: 5 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("Timezone")}
                    </span>
                    <Col lg={{ span: 12 }}>
                      <Row>
                        <Dropdown
                          className="ps-0 pe-0 restaurant-input-field"
                          onSelect={handleTimeZoneSelect}
                        >
                          <Dropdown.Toggle
                            variant="button"
                            className="dropdown-toggle btn-icon restaurant-dropdown "
                          >
                            {state.timezone !== "" ? state.timezone : t("Timezone")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="type-dropdown-menu">
                            {TIME_ZONES.sort().map((t, i) => (
                              <Dropdown.Item
                                className="type-dropdown-menu-item"
                                key={i}
                                eventKey={t}
                              >
                                {t}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row>
                <span className="add-restaurants-input-title ps-0">
                  {t("Address")}
                </span>

                <Autocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                  onPlaceSelected={(place) => {
                    onPlaceSelect(place);
                  }}
                  options={{
                    types: ["geocode", "establishment"],
                  }}
                  defaultValue={restaurantData ? restaurantData.address : ""}
                  className="restaurant-input-field"
                />
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row>
                <Col lg={6}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("City")}
                    </span>
                    <Form.Control
                      type="text"
                      className="restaurant-input-field"                      
                      required
                      onChange={(event) =>
                        setState({ ...state, city: event.target.value })
                      }
                      value={state.city}
                      defaultValue={restaurantData ? restaurantData.city : ""}
                    />
                  </Row>
                </Col>
                <Col lg={{ offset: 1, span: 5 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("State")}
                    </span>

                    <Form.Control
                      type="text"
                      className="restaurant-input-field"
                      required
                      onChange={(event) =>
                        setState({ ...state, state: event.target.value })
                      }
                      value={state.state}
                      defaultValue={restaurantData ? restaurantData.state : ""}
                    />
                  </Row>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row>
                <Col lg={6}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("Country")}
                    </span>
                    <Form.Control
                      type="text"
                      className="restaurant-input-field"
                      required
                      onChange={(event) =>
                        setState({ ...state, country: event.target.value })
                      }
                      value={state.country}
                      defaultValue={
                        restaurantData ? restaurantData.country : ""
                      }
                    />
                  </Row>
                </Col>

                <Col lg={{ offset: 1, span: 5 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("ZIPCode")}
                    </span>

                    <Form.Control
                      type="text"
                      className="restaurant-input-field"
                      required
                      onChange={(event) =>
                        setState({ ...state, zip_code: event.target.value })
                      }
                      defaultValue={
                        restaurantData ? restaurantData.zip_code : ""
                      }
                    />
                  </Row>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row>
                <Col lg={{ span: 6 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("Type")}
                    </span>
                    <Col lg={{ span: 12 }}>
                      <Row>
                        <Dropdown
                          className="ps-0 pe-0 restaurant-input-field"
                          onSelect={handleTypeSelect}
                        >
                          <Dropdown.Toggle
                            variant="button"
                            className="dropdown-toggle btn-icon restaurant-dropdown "
                          >
                            {state.type ? state.type : t("SelectType")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="type-dropdown-menu">
                            {RESTAURANTS_TYPES_LIST.map((t, i) => (
                              <Dropdown.Item
                                className="type-dropdown-menu-item"
                                key={i}
                                eventKey={t}
                              >
                                {t}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col lg={{span: isLabo ? 3 : 5, offset: 1}}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                      {t("SIRETNumber")}
                    </span>

                    <Form.Control
                      type="text"
                      className="restaurant-input-field"
                      required
                      onChange={(event) =>
                        setState({ ...state, siret_number: event.target.value })
                      }
                      defaultValue={
                        restaurantData ? restaurantData.siret_number : ""
                      }
                    />
                  </Row>
                </Col>
                
                {isLabo && <Col lg={{ offset: 1, span: 1 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0 mb-3">
                      {t("Labo")}
                    </span>
                      <Form className="ps-0 cursor-pointer">
                      <Form.Check // prettier-ignore
                        onChange={(e) => setState({ ...state, is_labo: e.target.checked })}
                        checked={state.is_labo}
                        type="switch"
                        id="custom-switch"
                      />
                    </Form>
                  </Row>
                </Col>}
              </Row>
            </Form.Group>

            <Row className="mt-4">
              <Col lg={6}>
                <span className="add-restaurants-input-title ps-0">
                  {t("Photo")}
                </span>
                <div className="">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>{t("Drag&Drop")}</p>
                  </div>
                  <aside>
                    <span className="add-restaurants-input-title m-0">
                      {t("AcceptedFiles")}
                    </span>
                    <div className="files-list">
                      <ul>
                        {files.map((file, i) => (
                          <li
                            key={i}
                            className="d-flex justify-content-between"
                          >
                            <div className="d-flex">
                              <img src={JPEG_ICON} alt="..." />
                              <p className="ps-3">
                                <span>{file.path}</span>
                                <span className="size">
                                  {parseInt(file.size * 0.001)} Kb
                                </span>
                              </p>
                            </div>
                            <div>
                              <img
                                src={EYE_ICON}
                                alt="..."
                                className="eye me-3"
                              />
                              <img
                                src={CLOSE_ICON}
                                alt="..."
                                className="close"
                                onClick={onRemoveFile(file, i)}
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </aside>
                  <aside style={thumbsContainer}>{thumbs}</aside>
                  {!restaurantData && fd.image_path && !files.length && (
                    <div style={thumb}>
                      <div style={thumbInner}>
                        <img src={fd.image_path} style={img} alt="..." />
                      </div>
                    </div>
                  )}
                  {restaurantData &&
                    restaurantData.image_path &&
                    !files.length && (
                      <div style={thumb}>
                        <div style={thumbInner}>
                          <img
                            src={restaurantData.image_path}
                            style={img}
                            alt="..."
                          />
                        </div>
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={{ offset: 1, span: 5 }}>
                <div className="vat-checkbox-group">
                  <span className="add-restaurants-input-title ps-0">{t("VAT")}</span>
                  <label    
                    className={`add-restaurants-input-title ps-0 ${
                      selectedRegion === "France" ? "selected-checkbox" : ""
                    }`}
                  >
                    <input
                      className="VAT-checkbox"
                      type="checkbox"
                      checked={selectedRegion === "France"}
                      onChange={() => handleCheckboxChange("France")}
                    />
                    France
                  </label>
                  <label
                    className={`add-restaurants-input-title ps-0 ${
                      selectedRegion === "England" ? "selected-checkbox" : ""
                    }`}
                  >
                    <input
                      className="VAT-checkbox"
                      type="checkbox"
                      checked={selectedRegion === "England"}
                      onChange={() => handleCheckboxChange("England")}
                    />
                    England
                  </label>
                  {/* <label
                    className={`add-restaurants-input-title ps-0 ${
                      selectedRegion === "Other" ? "selected-checkbox" : ""
                    }`}
                  >
                    <input
                      className="VAT-checkbox"
                      type="checkbox"
                      checked={selectedRegion === "Other"}
                      onChange={() => handleCheckboxChange("Other")}
                    />
                    Other
                  </label> */}
                </div>

                <div>
                  <Dropdown
                    className="ps-0 pe-0 restaurant-input-field vat-dropdown"
                    onSelect={(e) =>
                      handleDropdownSelect(
                        fakeDropdownData[selectedRegion].find((item) => item.name === e)
                      )
                    }
                  >
                    <Dropdown.Toggle className="dropdown-toggle btn-icon restaurant-dropdown">
                      <div className="dropdown-toggle-content">
                        <span className="selected-item-name">
                          {selectedItem?.name || "Select Item"}
                        </span>
                        {selectedItem && (
                          <div className="vat-edit">
                            <input
                              type="text"
                              value={editableVAT}
                              onChange={handleVATChange}
                              className="vat-input-field"
                              placeholder="Enter VAT"
                              style={{
                                width: "45px",
                                border: "none",
                                padding: "4px",
                                textAlign: "center",
                              }}
                            />
                            <img className="edit-icon" src={EditIcon} alt="Edit" />
                          </div>
                        )}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="type-dropdown-menu">
                      {fakeDropdownData[selectedRegion].map((item, index) => (
                        <Dropdown.Item key={index} eventKey={item.name}>
                          <div className="dropdown-item-content">
                          <span className="item-name">{t(item.name)}</span>
                            <span className="item-vat">{item.vat}</span>
                          </div>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>

         {(showInvoiceContainer && restaurantInvoice) && <div className="invoice-container">   
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="add-restaurant-heading">{restaurantData ? t("EditRestaurantInvoice") : t("AddRestaurant")}</h2>
              <img
                className="close-icon"
                src={CLOSE_ICON}
                alt="Close"
                onClick={() => {
                  setShowInvoiceContainer(false);
                }}
              />
            </div>  
            <Form.Group className="mb-2">
              <Row>
                <Col lg={6}>
                  <Row>
                      <span className="add-restaurants-input-title ps-0">
                      {t("Name")}
                    </span>
                    <Form.Control
                      type="text"
                      className=""
                      onChange={(event) =>
                        setRestaurantInvoice({ ...restaurantInvoice, name: event.target.value })
                      }
                      defaultValue={restaurantInvoice.name}
                    />
                  </Row>
                </Col>
                <Col lg={{ offset: 1, span: 5 }}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                    {t("Contact name")}
                    </span>
                    <Form.Control
                      type="text"
                      className=""
                      onChange={(event) =>
                        setRestaurantInvoice({ ...restaurantInvoice, contact_name: event.target.value })
                      }
                      defaultValue={restaurantInvoice.contact_name}
                    />
                  </Row>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row>
              <Col lg={6}>
                  <Row>
                    <span className="add-restaurants-input-title ps-0">
                    {t("Email")}
                    </span>
                    <Form.Control
                      type="email"
                      required
                      onChange={(event) =>
                        setRestaurantInvoice({ ...restaurantInvoice, email: event.target.value })
                      }
                      defaultValue={restaurantInvoice.email}
                    />
                  </Row>
                </Col>
                <Col lg={{ offset: 1, span: 5 }}>
                    <Row>
                    <span className="add-restaurants-input-title ps-0">
                        {t("Phone number")}
                      </span>
                      <Form.Control
                        type="text"
                        className=""
                        onChange={(event) =>
                          setRestaurantInvoice({ ...restaurantInvoice, phone_number: event.target.value })
                        }
                        defaultValue={restaurantInvoice.phone_number}
                      />
                    </Row>
                  </Col>
                </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row>
                <Col lg={6}>
                  <Row>
                  <span className="add-restaurants-input-title ps-0">
                  {t("Address")}
                   </span>

                    <Autocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                      onPlaceSelected={(place) => {
                        onPlaceSelect(place, 'invoice');
                      }}
                      options={{
                        types: ["geocode", "establishment"],
                      }}
                      defaultValue={restaurantInvoice.address}
                      className="restaurant-input-field"
                    />
                  </Row>
                </Col>
                <Col lg={{ offset: 1, span: 5 }}>
                  <Row>
                  <span className="add-restaurants-input-title ps-0">
                    {t("SIRET number")}
                    </span>
                    <Form.Control
                      type="text"
                      required
                      onChange={(event) =>
                        setRestaurantInvoice({ ...restaurantInvoice, siret_number: event.target.value })
                      }
                      defaultValue={restaurantInvoice.siret_number}
                    />
                  </Row>
                </Col>
              </Row>
            </Form.Group>
            </div>
          }
           {(!showInvoiceContainer && restaurantInvoice?.name) && <Row className="d-flex justify-content-center mt-5">
              <Button onClick={() => setShowInvoiceContainer(!showInvoiceContainer)} className="add-restaurant-confirm-btn w-50 ">{t("EditRestaurantInvoice")}</Button>
            </Row>}

            <Row style={{ marginTop: showInvoiceContainer ? "40px" : "100px"}}>
              <Modal.Footer className="add-restaurants-modal-footer pe-0">
                <Button type="submit" className="add-restaurant-confirm-btn">
                  {t("Confirm")}
                </Button>
              </Modal.Footer>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
    </div>
  );
}

export default AddRestaurantsModal;
