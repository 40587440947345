import React, { useState, useEffect } from "react";

import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Card,
  Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";
import request from "services/request";

import UploadedIcon from "assets/images/uploaded_meal.png";
import QuantityContainer from "components/quantityBtnContainer";

function Text({caption, style}) {
  let customStyle = {...style, border: 'none', width: "auto"}
  return (
    <>
      <span style={customStyle} className="add-restaurants-input-title form-control">{caption}</span>
    </>
  );
}

function CustomModal({
  onHide,
  show,
  data: { selectedIngredient },
  isUploadedModal = true,
  setShowSaveOrderModal,
  getLaboProductionPlanningData
}) {
  const { t } = useTranslation();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isUploaded, setIsUploaded] = useState(false);
  const { setLoading, setError } = useLoading();
  const [files, setFiles] = useState([]);
  const [fd, setFd] = useState(selectedIngredient);

  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    setSampleRestaurantModal,
  } = useUserData();

  useEffect(() => {
    setFiles([]);
    setIsSubmitDisabled(true);
    setIsUploaded(false);
  }, [show]);

  useEffect(() => {
    if (
      fd?.name &&
      fd?.cost_excl_tax &&
      fd?.category &&
      fd?.unit
    ) {
      setIsSubmitDisabled(false);
      return;
    }
    setIsSubmitDisabled(true);
  }, [fd]);

const updateOrderItemQty = async(meal, value) => {
    setFd(prevStocks => {
      return {
        ...fd,
        stock: {...prevStocks.stock, stock: value},
        difference: value - (prevStocks?.stock?.theoretical_stock || 0)
      }})
   }

  const onSubmit = async (ev) => {
    ev.preventDefault();
    try {
      if (isRestaurantLoaded && !hasRetaurants) {
        setSampleRestaurantModal(true);
        onHide();
        return;
      }
      setIsSubmitDisabled(true);
      setLoading(true);
      const payload = {
        stocks: [
          {
            meal_id: fd?.id,
            stock: fd?.stock?.stock,
            difference: fd?.difference || 0,
            restaurant_id: fd.restaurant_id,
          }
        ],
      };
      const resultQuery = await request.post(`/meal-stocks`, payload);

      if (resultQuery.ok) {
        getLaboProductionPlanningData()
        onHide()
        setShowSaveOrderModal(true)
        return
      }
    } catch (error) {
      setIsSubmitDisabled(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="add-ingredient-labo"
      backdropClassName="add-ingredient-backdrop"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
       
      </Modal.Header>

      <Modal.Body>
        <Container className="p-0">
          {isUploadedModal && isUploaded && (
            <div className="d-flex justify-content-center flex-column  text-center upload-container">
              <div>
                <img src={UploadedIcon} alt="..." />
              </div>
              <div className="heading mt-5">
                <label>{t("Order uploaded")} !</label>
              </div>
              <div className="subheading mt-2 mb-5">
                <label>
                  {t("Your Order has been successfully uploaded")}.
                </label>
              </div>
              <div>
                <Button className="add-restaurant-confirm-btn" onClick={onHide}>
                  {t("OK")}
                </Button>
              </div>
            </div>
          )}
          {!isUploaded && (
            <Form noValidate className="ps-0 pe-0" onSubmit={onSubmit}>
              <Row className="mt-4">
                  <Col lg={12}>
                    <Card className="mb-0 bg-transparent">
                      <div className="p-4 allergens-container">

                        <div className="row custom-table h-100">
                          <div className="col-lg-12 h-100 p-0">
                            <div className="tablescroll">
                              <table className="table" style={{backgroundColor: "transparent"}}>
                                <thead>
                                  <tr>
                                    <td
                                      className="text-center"
                                      style={{ width: "30%", textAlign: "center" }}
                                    >
                                      {t("Name")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "30%" }}
                                    >
                                      {t("Inventory")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("Theoretical stock")}
                                    </td>
                                    {/* <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("Inventory variance")}
                                    </td> */}
                                    <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("Price")}
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                    <tr 
                                      className="flex justify-between"
                                    >
                                      <td style={{ height: "50px", textAlign: "center" }}>
                                        <Text style={{width: "100%",   display: "inline-block", whiteSpace: "initial", backgroundColor: "transparent"}}  caption={fd?.name} />
                                      </td>
                                      <td className="text-center" style={{ width: "200px" }}>
                                      <QuantityContainer currentValue={fd?.stock?.stock ? fd?.stock?.stock : 0} setCurrentValue={(_, value) => {
                                        updateOrderItemQty(fd, value)
                                      }} />
                                      </td> 
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text style={{ backgroundColor: "transparent"}} caption={fd?.stock?.theoretical_stock} />
                                      </td>
                                      {/* <td className="text-center" style={{ width: "100px" }}>
                                        <Text style={{ backgroundColor: "transparent" }} caption={quantity - selectedIngredient.theoretical_stock} />
                                      </td> */}
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text style={{ backgroundColor: "transparent"}} caption={fd?.unit_price} />
                                      </td>
                                    </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>

              <Modal.Footer className="labo-order-modal-footer">
                <Button
                  type="submit"
                  className="labo-order-modal-btn"
                  style={{ backgroundColor: "#873CFC", width: "50%"}}
                  // disabled={selectedIngredient.order_status === "sent"}
                >
                  {t("Save")}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default CustomModal;
