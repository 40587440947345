import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Card,
} from "react-bootstrap";
import Pdf from "react-to-pdf";
import { useTranslation } from "react-i18next";
import "moment/locale/fr"; // Import French locale
import "moment/locale/en-gb"; // Import English locale (optional)


import FullSoonLogo from "assets/images/fullsoon_logo_1.svg";


import "./index.scss"
import moment from "moment";
import { useUserData } from "contexts/AuthContextManagement";
import React from "react";

function Text({caption, style}) {
  let customStyle = {...style, border: 'none', width: "auto", paddingLeft: "0"}
  return (
    <>
      <span style={customStyle} className="add-restaurants-input form-control bg-transparent">{caption}</span>
    </>
  );
}

const options = {
  orientation: "portrait",
  unit: "px",
  format: [window.outerHeight * 1.2, window.outerWidth * 0.6],
};

const ref = React.createRef();

function ConsolidateModal({
  onHide,
  show,
  formData,
  selectedRows,
}) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { selectedRestaurant} = useUserData();
  moment.locale(currentLanguage)
  let selectedIngredient = selectedRows[0] || {};

// Step 1: Filter orders with status 1
let filteredOrders = selectedRows.filter(order => order.status === "1");

// Step 2: Process the filtered orders and group labo_items
let groupedOrders = filteredOrders.reduce((acc, order) => {
  // Prepare order data
  const { order_number, sent_date, restaurant, labo_items } = order;
  
  // Initialize total_price for the current order
  
  // Calculate total_price by summing up product_price * product_quantity
  const total_price = labo_items.reduce((prev, current) => prev + current.product_price * current.product_quantity, 0)
  
  // Prepare a new order object
  const orderData = {
    order_number,
    sent_date,
    total_price,
  };

  // Step 3: Group by restaurant.id (same restaurant group together)
  const restaurantName = restaurant.name;
  if (!acc[restaurantName]) {
    acc[restaurantName] = [];
  }
  acc[restaurantName].push(orderData);

  return acc;
}, {});

  const totalPrice = filteredOrders.reduce((sum, row) => {
    const laboItemsTotal = row.labo_items.reduce(
      (itemSum, item) => itemSum + item.product_price * item.product_quantity,
      0
    );
    return sum + laboItemsTotal;
  }, 0)?.toFixed(2);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="order-pdf-modal"
      backdropClassName="add-ingredient-backdrop"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
      </Modal.Header>

      <Modal.Body>
        <Container style={{ maxWidth: "90%" }}>
         <div className="modal-header">
              <h3>{t("Good delivery consolidation for headquarters")}</h3>
              <Pdf options={options} targetRef={ref} filename={`labo-order.pdf`}>
              {({ toPdf }) =>  <Button 
                onClick={toPdf}
                variant="primary">
                {t("Download")}
              </Button>}
            </Pdf>
         </div>
         <Card ref={ref} className="modal-card-container">
            <div className="logo-image"><img src={FullSoonLogo} alt="logo" /></div>
            <div className="modal-sub-container">
              <div className="left-side" style={{ width: "100%" }}>
                <div className="date-container">
                  <div style={{ fontSize: "21px", fontWeight: "bold"}}>{t("Delivery note")} <span style={{ color: "#8232FF" }}>{selectedRestaurant?.name}</span></div>
                  <div style={{ fontSize: "21px", fontWeight: "bold"}}>{t("From")} <span style={{ color: "#8232FF" }}>{moment(formData?.start_date, "YYYY-MM-DD").format("DD MMMM YYYY")}</span> {t("to")} <span style={{ color: "#8232FF" }}>{moment(formData?.end_date, "YYYY-MM-DD").format("DD MMMM YYYY")}</span></div>
                </div>
                <Card className="labo-container-modal">
                  <Card.Header style={{ padding: "0px" }}>
                    <div style={{ color: "#8232FF", fontSize: "21px", fontWeight: "bold"}}>{t("Labo Details")}</div>
                  </Card.Header>
                  <Card.Body>
                        <Row>
                          <Col lg={6} className="p-0">
                            <span className="add-restaurants-input">
                              {t("Name")}
                            </span>
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient?.labo_restaurant?.invoice?.name} />
                          </Col>
                          <Col lg={{ span: 5, offset: 1 }} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("Phone")}
                            </span> 
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient?.labo_restaurant?.invoice?.phone_number} />
                          </Col>
                      </Row>
                      <Row>
                          <Col lg={6} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("Mail")}
                            </span>
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient?.labo_restaurant?.invoice?.email} />
                          </Col>
                          <Col lg={{ span: 5, offset: 1 }} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("Address")}
                            </span> 
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient?.labo_restaurant?.invoice?.address} />
                          </Col>
                      </Row>
                      <Row>
                          <Col lg={6} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("SIRET Number")}
                            </span>
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient?.labo_restaurant?.invoice?.siret_number} />
                          </Col>
                          <Col lg={{ span: 5, offset: 1 }} className="p-0">
                            <span className="add-restaurants-input ps-0">
                              {t("Contact name")}
                            </span> 
                            <Text style={{width: "100%", whiteSpace: "initial", fontSize: "18px", fontWeight: "bold" }}  caption={selectedIngredient?.labo_restaurant?.invoice?.contact_name} />
                          </Col>
                      </Row>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <Card className="delivery-container">
              <Card.Header className="delivery-header">
                    <div style={{ color: "#8232FF", fontSize: "21px", fontWeight: "bold"}}>{t("Delivery details")}</div>
              </Card.Header>
              <Card.Body className="delivery-body">
                {Object.keys(groupedOrders).map((orderItem, index) => (
                  <Row key={index}>
                  <Row>
                  <Col lg={6} className="p-0">
                    <p className="consolidate-details-sub-header">{orderItem} - <span className="fullsoon-color">{(groupedOrders[orderItem].reduce((acc, cur) => acc + cur.total_price, 0))?.toFixed(2)}€ {t("excl.tax")}</span></p>
                    {/* <p className="consolidate-details-sub-header">{t("From")} - <span className="fullsoon-color">{moment(orderItem?.createdAt, "YYYY-MM-DD").format("DD MMMM YYYY")}</span> {t("to")} <span className="fullsoon-color">{moment(orderItem?.send_date, "YYYY-MM-DD").format("DD MMMM YYYY")}</span></p> */}
                  </Col>
                  </Row>
                  <Row className="mt-4">
                  <Col lg={12}>
                      <div className="p-4 allergens-container">
                        <div className="row custom-table h-100">
                          <div className="col-lg-12 h-100 p-0">
                            <div className="tablescroll">
                              <table className="table bg-transparent">
                                <thead>
                                  <tr>
                                    <td style={{ width: "25%", fontSize: "13px", fontWeight: "bold" }}>
                                    {t("Reference")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "50%", fontSize: "13px", fontWeight: "bold" }}
                                    >
                                      {t("Delivery date")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%", fontSize: "13px", fontWeight: "bold", color: "#8232FF" }}
                                    >
                                      {t("Total Price")}{<sub>{t(" EXCL. VAT")}</sub>}
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {groupedOrders[orderItem]?.map((laboItem, index) => (
                                    <tr
                                      className="flex justify-between"
                                      key={index}
                                    >
                                      <td style={{ height: "50px" }}>
                                        <Text style={{width: "100%", display: "inline-block", whiteSpace: "initial"}}  caption={laboItem?.order_number} />
                                      </td>
                                      <td className="text-center" style={{ width: "200px" }}>
                                        <Text style={{overflow: "hidden"}} caption={laboItem?.sent_date ? laboItem.sent_date : laboItem.shipping_date} />
                                      </td>
                                      <td style={{ width: "100px" }} className="text-center">
                                      <Text caption={laboItem.total_price?.toFixed(2)} />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                  </Col>
                  </Row>
                  </Row>
                ))}
               
              </Card.Body>
              <Card.Footer style={{ height: "100px", width: "100%", backgroundColor: "#8232FF", display: "flex", justifyContent: "end", alignItems: "center", color: "white", paddingRight: "50px" }}>
                  <span>
                    <span style={{ fontSize: "18px", fontWeight: "bold" }}><sub>{t("Total")} <sub>INCL. VAT  </sub></sub><span style={{ fontSize: "32px", fontWeight: "bold" }}>{totalPrice?.split(".")[0]}</span><sub>,{totalPrice?.split(".")[1]}€</sub></span>
                  </span>
              </Card.Footer>
                
            </Card>
         </Card>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ConsolidateModal;


// let labo_orders = [
//       {
//           "id": "3e9a0247-390d-426b-bcb6-a0d35499af9a",
//           "order_number": "LABO-BOELDIEU-15",
//           "send_date": "2025-02-07",
//           "total_price": 8.96,
//           "delivery_temperature_celcius": null,
//           "status": "1",
//           "createdAt": "2025-02-04T13:26:15.000Z",
//           "restaurant": {
//               "id": "1f7fd077-3668-43ae-8baf-9914d16d7076",
//               "name": "BOLKIRI - Montrouge",
//               "address": "25 Av. de la République, 92120 Montrouge, France",
//               "siret_number": null,
//               "invoice": {
//                   "name": "BOLKIRI",
//                   "contact_name": "BOLKIRI Contact",
//                   "phone_number": "234234234234",
//                   "email": "bolkiri@mail.com",
//                   "address": "Paris, TX, USA",
//                   "siret_number": "123213"
//               }
//           },
//           "labo_restaurant": {
//               "id": "dea35f90-d222-4171-b87a-91db00154693",
//               "name": "Test labo 2",
//               "address": "Lahore City, Lahore, Punjab, Pakistan",
//               "siret_number": "123123",
//               "invoice": {
//                   "name": "Fake Labo2 legal",
//                   "contact_name": "Fake Labo2 legal conatct",
//                   "phone_number": "768415131",
//                   "email": "faisalgulzar1243@gmail.com",
//                   "address": "Lahore City, Lahore, Punjab, Pakistan",
//                   "siret_number": "6457896315"
//               }
//           },
//           "labo_items": [
//               {
//                   "id": "1952a8bb-c4ec-4b2d-b4ba-89454609f158",
//                   "ingredient_id": "da233ad5-96ed-44a8-a654-c60e1833ed1a",
//                   "provider_ingredient_id": "8011768e-a93f-4865-ad39-54295a491010",
//                   "name": "Brownie *",
//                   "provider_reference_name": "Brownie *",
//                   "provider_reference_number": "1234",
//                   "conditioning": "UNITE",
//                   "product_quantity": 7,
//                   "product_price": 8.05,
//                   "unit_cost": 2.88,
//                   "unit_price": 2.5,
//                   "unit_margin": -0.38,
//                   "is_send": true
//               },
//               {
//                   "id": "1f393d9a-8853-4f36-b8da-1a8dc381dc41",
//                   "ingredient_id": "5822f6ad-d664-4d0f-8ada-ab74ac61bf41",
//                   "provider_ingredient_id": "69c17834-110b-45d6-8b6b-268ca729a55a",
//                   "name": "Baguette Tradition Ciabatta",
//                   "provider_reference_name": "Baguette Tradition",
//                   "provider_reference_number": "1111",
//                   "conditioning": "UNITE",
//                   "product_quantity": 4,
//                   "product_price": 0.04,
//                   "unit_cost": 1.25,
//                   "unit_price": 0.75,
//                   "unit_margin": -0.5,
//                   "is_send": true
//               },
//               {
//                   "id": "43b06138-61db-4882-a261-208ae377c2b8",
//                   "ingredient_id": "2cca0dc5-4660-4e9e-960b-23243b2479d0",
//                   "provider_ingredient_id": "5629bd62-c8d5-45bc-8f9b-dfd8799003d7",
//                   "name": "Fougasse Aux Olives *",
//                   "provider_reference_name": "Fougasse Aux Olives *",
//                   "provider_reference_number": null,
//                   "conditioning": "UNITE",
//                   "product_quantity": 3,
//                   "product_price": 0.87,
//                   "unit_cost": null,
//                   "unit_price": null,
//                   "unit_margin": null,
//                   "is_send": true
//               }
//           ]
//       },
//       {
//           "id": "fb0ad704-c97a-4c58-86c3-5600ae5dc9b6",
//           "order_number": "LABO-BOELDIEU-25",
//           "send_date": "2025-02-07",
//           "total_price": 5.3,
//           "delivery_temperature_celcius": null,
//           "status": "0",
//           "createdAt": "2025-02-04T13:25:33.000Z",
//           "restaurant": {
//               "id": "702bab05-4292-4a8f-beb2-7144d9002ac6",
//               "name": "Paris Baguette Chatelet",
//               "address": "11 Rue Jean Lantier, 75001 Paris, France",
//               "siret_number": null,
//               "invoice": null
//           },
//           "labo_restaurant": {
//               "id": "dea35f90-d222-4171-b87a-91db00154693",
//               "name": "Test labo 2",
//               "address": "Lahore City, Lahore, Punjab, Pakistan",
//               "siret_number": "123123",
//               "invoice": {
//                   "name": "Fake Labo2 legal",
//                   "contact_name": "Fake Labo2 legal conatct",
//                   "phone_number": "768415131",
//                   "email": "faisalgulzar1243@gmail.com",
//                   "address": "Lahore City, Lahore, Punjab, Pakistan",
//                   "siret_number": "6457896315"
//               }
//           },
//           "labo_items": [
//               {
//                   "id": "7ca445d4-e562-4c9c-970e-54d8dd6371ef",
//                   "ingredient_id": "88f13112-4e88-4a2e-b434-3c557befc995",
//                   "provider_ingredient_id": "f8bb77ad-2669-43c8-81ec-40bbd442f848",
//                   "name": "Baguette Tradition Ciabatta",
//                   "provider_reference_name": "Baguette Tradition",
//                   "provider_reference_number": "1111",
//                   "conditioning": "UNITE",
//                   "product_quantity": 7,
//                   "product_price": 0.7,
//                   "unit_cost": 1.25,
//                   "unit_price": 0.75,
//                   "unit_margin": -0.5,
//                   "is_send": false
//               },
//               {
//                   "id": "ce1ee7b3-3bd0-4201-9fdb-26a99e13e9bd",
//                   "ingredient_id": "d55e7bc8-ef73-4f73-8035-479bded61ad7",
//                   "provider_ingredient_id": "c270ac01-f594-4baa-94d2-4205995291d3",
//                   "name": "Brownie *",
//                   "provider_reference_name": "Brownie *",
//                   "provider_reference_number": "1234",
//                   "conditioning": "UNITE",
//                   "product_quantity": 4,
//                   "product_price": 4.6,
//                   "unit_cost": 2.88,
//                   "unit_price": 2.5,
//                   "unit_margin": -0.38,
//                   "is_send": false
//               }
//           ]
//       },
//       {
//           "id": "85e19f86-8222-4e03-9045-8654b05c598e",
//           "order_number": "LABO-BOELDIEU-24",
//           "send_date": "2025-02-07",
//           "total_price": 9.93,
//           "delivery_temperature_celcius": null,
//           "status": "1",
//           "createdAt": "2025-02-04T13:22:26.000Z",
//           "restaurant": {
//               "id": "702bab05-4292-4a8f-beb2-7144d9002ac6",
//               "name": "Paris Baguette Chatelet",
//               "address": "11 Rue Jean Lantier, 75001 Paris, France",
//               "siret_number": null,
//               "invoice": null
//           },
//           "labo_restaurant": {
//               "id": "dea35f90-d222-4171-b87a-91db00154693",
//               "name": "Test labo 2",
//               "address": "Lahore City, Lahore, Punjab, Pakistan",
//               "siret_number": "123123",
//               "invoice": {
//                   "name": "Fake Labo2 legal",
//                   "contact_name": "Fake Labo2 legal conatct",
//                   "phone_number": "768415131",
//                   "email": "faisalgulzar1243@gmail.com",
//                   "address": "Lahore City, Lahore, Punjab, Pakistan",
//                   "siret_number": "6457896315"
//               }
//           },
//           "labo_items": [
//               {
//                   "id": "6838623a-2ca8-4ba4-b6da-47cf021da1f9",
//                   "ingredient_id": "fcf03982-cdd1-4a54-916d-8af3abe28bf7",
//                   "provider_ingredient_id": "93c5f398-f9f1-4484-90c7-e2d2ed97d465",
//                   "name": "Brioche Au Sucre *",
//                   "provider_reference_name": "Brioche Au Sucre *",
//                   "provider_reference_number": "1235",
//                   "conditioning": "UNITE",
//                   "product_quantity": 4,
//                   "product_price": 1.12,
//                   "unit_cost": 1.4,
//                   "unit_price": 1.25,
//                   "unit_margin": -0.15,
//                   "is_send": true
//               },
//               {
//                   "id": "9e2f1ea4-b4ab-40e3-85cd-f1bc39e7c573",
//                   "ingredient_id": "d55e7bc8-ef73-4f73-8035-479bded61ad7",
//                   "provider_ingredient_id": "c270ac01-f594-4baa-94d2-4205995291d3",
//                   "name": "Brownie *",
//                   "provider_reference_name": "Brownie *",
//                   "provider_reference_number": "1234",
//                   "conditioning": "UNITE",
//                   "product_quantity": 5,
//                   "product_price": 5.75,
//                   "unit_cost": 2.88,
//                   "unit_price": 2.5,
//                   "unit_margin": -0.38,
//                   "is_send": true
//               },
//               {
//                   "id": "f3f66636-65df-4e69-830a-983336756c40",
//                   "ingredient_id": "a5069f8c-51fa-413f-bc7f-32b75afa7680",
//                   "provider_ingredient_id": "13e1eb21-6c1c-44b4-8881-4f7a46c2c928",
//                   "name": "Baguette Tradition *",
//                   "provider_reference_name": "Baguette Tradition *",
//                   "provider_reference_number": "1111",
//                   "conditioning": "UNITE",
//                   "product_quantity": 18,
//                   "product_price": 3.06,
//                   "unit_cost": 1.25,
//                   "unit_price": 0.75,
//                   "unit_margin": -0.5,
//                   "is_send": true
//               }
//           ]
//       },
//       {
//           "id": "25a067f0-fc0a-40ed-9deb-d65d963c2caa",
//           "order_number": "LABO-BOELDIEU-14",
//           "send_date": "2025-02-07",
//           "total_price": 3.65,
//           "delivery_temperature_celcius": null,
//           "status": "1",
//           "createdAt": "2025-02-03T13:29:43.000Z",
//           "restaurant": {
//               "id": "1f7fd077-3668-43ae-8baf-9914d16d7076",
//               "name": "BOLKIRI - Montrouge",
//               "address": "25 Av. de la République, 92120 Montrouge, France",
//               "siret_number": null,
//               "invoice": {
//                   "name": "BOLKIRI",
//                   "contact_name": "BOLKIRI Contact",
//                   "phone_number": "234234234234",
//                   "email": "bolkiri@mail.com",
//                   "address": "Paris, TX, USA",
//                   "siret_number": "123213"
//               }
//           },
//           "labo_restaurant": {
//               "id": "dea35f90-d222-4171-b87a-91db00154693",
//               "name": "Test labo 2",
//               "address": "Lahore City, Lahore, Punjab, Pakistan",
//               "siret_number": "123123",
//               "invoice": {
//                   "name": "Fake Labo2 legal",
//                   "contact_name": "Fake Labo2 legal conatct",
//                   "phone_number": "768415131",
//                   "email": "faisalgulzar1243@gmail.com",
//                   "address": "Lahore City, Lahore, Punjab, Pakistan",
//                   "siret_number": "6457896315"
//               }
//           },
//           "labo_items": [
//               {
//                   "id": "21616db8-ee94-40f1-b375-170f193749bb",
//                   "ingredient_id": "535f24bb-f9ff-45a2-9736-4cc6937d7208",
//                   "provider_ingredient_id": "47d4a24f-26d3-443e-9abb-d7c60affbd32",
//                   "name": "Baguette Tradition *",
//                   "provider_reference_name": "Baguette Tradition *",
//                   "provider_reference_number": "1111",
//                   "conditioning": "UNITE",
//                   "product_quantity": 5,
//                   "product_price": 0.85,
//                   "unit_cost": 1.25,
//                   "unit_price": 0.75,
//                   "unit_margin": -0.5,
//                   "is_send": true
//               },
//               {
//                   "id": "c549f748-c876-4d52-81d5-3bb2544f69cd",
//                   "ingredient_id": "be60ebaa-9f04-40ca-a079-3b12a22f8e07",
//                   "provider_ingredient_id": "cfe5d13e-b291-4dbc-b3c6-3970fae35af1",
//                   "name": "Brioche Au Sucre *",
//                   "provider_reference_name": "Brioche Au Sucre *",
//                   "provider_reference_number": "1235",
//                   "conditioning": "UNITE",
//                   "product_quantity": 10,
//                   "product_price": 2.8,
//                   "unit_cost": 1.4,
//                   "unit_price": 1.25,
//                   "unit_margin": -0.15,
//                   "is_send": true
//               }
//           ]
//       },
//   ]
