import { useState, useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import request from "services/request";

import './index.scss';
import { useUserData } from "contexts/AuthContextManagement";


function AddRestaurantDetailModal(props) {
  const { t } = useTranslation();
  const { selectedRestaurantId } = useUserData();
  const { restaurantData } = props;

  const intialState = {
    name: "",
    contact_name: "",
    phone_number: "",
    email: "",
    address: "",
    siret_number: "",
    restaurant_id: "",
  };
  const [state, setState] = useState(intialState);
  const [isAlreadyAdded, setIsAlreadyAdded] = useState(false);

 const getCurrentRestaurant = async () => {
  const result = await request.get(`restaurants/${selectedRestaurantId}`);
  if(result?.restaurant_detail) {
    setIsAlreadyAdded(true)
  }else {
    setIsAlreadyAdded(false)
  }
 }

  useEffect(() => {
    getCurrentRestaurant()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTimeZoneSelect = (val) => {
    setState({ ...state, timezone: val });
  };

  const onPlaceSelect = (place) => {
    setState((prevState) => ({
      ...prevState,
      address: place.formatted_address,
    }));
  };

  const handleSubmit = async (event) => {
    // Prevent default behavior
    event.preventDefault();
    try {
      const result = await request.post(`/restaurant-invoices`, {...state, restaurant_id: selectedRestaurantId});
      const data = await result.clone().json();

      // check for error response
      if (result.ok) {
        props.onHide()
      } 
    } catch (error) {
     console.log({error})
    }
    props.onHide()
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-restaurant"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
        <Modal.Title
          className="add-restaurants-modal-title"
          id="contained-modal-title-vcenter"
        >
          {restaurantData ? t("EditRestaurant") : t("AddRestaurantInvoice")}
        </Modal.Title>
      </Modal.Header>
      
      <Modal.Body className="add-restaurants-modal-body">
        <Container>
          <Form className="ps-0 pe-0" onSubmit={handleSubmit}>
            <Form.Group className="mb-2">
              <Row>
                <Col className="p-0">
                <span className="add-restaurants-input-title ps-0">
                  {t("Name")}
                </span>
                <Form.Control
                  type="text"
                  className=""
                  onChange={(event) =>
                    setState({ ...state, name: event.target.value })
                  }
                  defaultValue={state.name}
                />
                </Col>
                <Col className="pe-1">
                <span className="add-restaurants-input-title ps-0">
                  {t("Contact name")}
                </span>
                <Form.Control
                  type="text"
                  className=""
                  onChange={(event) =>
                    setState({ ...state, contact_name: event.target.value })
                  }
                  defaultValue={state.contact_name}
                />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-2">
              <Row>
                <Col className="ps-0">
                <span className="add-restaurants-input-title ps-0">
                  {t("Phone number")}
                </span>
                <Form.Control
                  type="text"
                  className=""
                  onChange={(event) =>
                    setState({ ...state, phone_number: event.target.value })
                  }
                  defaultValue={state.phone_number}
                />
                </Col>

                <Col className="p-0">
                <span className="add-restaurants-input-title ps-0">
                  {t("Email")}
                </span>
                <Form.Control
                  type="email"
                  required
                  onChange={(event) =>
                    setState({ ...state, email: event.target.value })
                  }
                  defaultValue={state.email}
                />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-2">
              <Row style={{ borderRadius: "4px"}}>
                <Col className="ps-0">
                <span className="add-restaurants-input-title ps-0">
                  {t("Address")}
                </span>

                <Autocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                  onPlaceSelected={(place) => {
                    onPlaceSelect(place);
                  }}
                  options={{
                    types: ["geocode", "establishment"],
                  }}
                  defaultValue={state.address}
                  className="restaurant-input-field"
                />
                </Col>
                 <Col className="p-0">
                <span className="add-restaurants-input-title ps-0">
                  {t("SIRET number")}
                </span>
                <Form.Control
                  type="text"
                  required
                  onChange={(event) =>
                    setState({ ...state, siret_number: event.target.value })
                  }
                  defaultValue={state.siret_number}
                />
                </Col>
              </Row>
            </Form.Group>

            {/* <Form.Group className="mb-2">
              <Row>
                <Col className="p-0">
                <span className="add-restaurants-input-title ps-0">
                  {t("SIRET number")}
                </span>
                <Form.Control
                  type="text"
                  required
                  onChange={(event) =>
                    setState({ ...state, siret_number: event.target.value })
                  }
                  defaultValue={state.siret_number}
                />
                </Col>
              </Row>
            </Form.Group> */}

            <Row>
              <Modal.Footer className="add-restaurants-modal-footer pe-0">
                <Button type="submit" disabled={isAlreadyAdded} className="add-restaurant-confirm-btn">
                  {t("Confirm")}
                </Button>
              </Modal.Footer>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default AddRestaurantDetailModal;
