import React, { useState, useEffect } from "react";
import convertUnits from 'convert-units';

import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Card,
  Badge,
  Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";
import request from "services/request";

import UploadedIcon from "assets/images/uploaded_meal.png";
import QuantityContainer from "components/quantityBtnContainer";

import "./index.scss"

function Text({caption, style}) {
  let customStyle = {...style, border: 'none', width: "auto"}
  return (
    <>
      <span style={customStyle} className="add-restaurants-input-title form-control bg-transparent">{caption}</span>
    </>
  );
}

function CustomModal({
  onHide,
  show,
  data: { selectedIngredient },
  isUploadedModal = true,
  setShowSentOrderModal,
  setShowSaveOrderModal,
  getLaboOrdersData,
  setCurrentItems,
  currentItems,
  setShowOrderPdfModal
}) {
  const { t } = useTranslation();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isUploaded, setIsUploaded] = useState(false);
  const { setLoading, setError } = useLoading();
  const [files, setFiles] = useState([]);
  const [fd, setFd] = useState({...selectedIngredient, delivery_temperature_celcius: null});

  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    setSampleRestaurantModal,
  } = useUserData();

  useEffect(() => {
    setFd({ allergens: [], details: [] });
    setFiles([]);
    setIsSubmitDisabled(true);
    setIsUploaded(false);
  }, [show]);

  useEffect(() => {
    if (
      fd?.name &&
      fd?.cost_excl_tax &&
      fd?.category &&
      fd?.unit
    ) {
      setIsSubmitDisabled(false);
      return;
    }
    setIsSubmitDisabled(true);
  }, [fd]);

  useEffect(() => {
    if (selectedIngredient) {
      setFd({
        ...selectedIngredient,
        cost_excl_tax: (selectedIngredient.cost_excl_tax / selectedIngredient.details?.find(p => p.is_default === true)?.provider_ingredient?.recipe_unit_quantity)?.toFixed(4),
        allergens: selectedIngredient?.allergens?.map((a) => a.id),
      });
    }
  }, [selectedIngredient]);

  const sendOrderHandler = async() => {
    if(isRestaurantLoaded && !hasRetaurants) {
      let updatedCurrentItems = currentItems?.map(item => {
        if(item.id === selectedIngredient.id) {
          return {...item, order_status: "sent", delievry_status: "active"}
        }
        return item
      })
      setCurrentItems(updatedCurrentItems)
    }
    let payload = {
      labo_order_ids: [selectedIngredient.id],
    }
    let res = await request.post(`/labos/orders/delivery`, payload); 

    if(res.status === 200) {
      await getLaboOrdersData();
    }

    setShowSentOrderModal(true);
    onHide();
  }

  const saveOrder = async(isSend = false) => {
    // if(isRestaurantLoaded && !hasRetaurants) {
    //   let updatedCurrentItems = currentItems?.map(item => {
    //     if(item.id === selectedIngredient.id) {
    //       return {...item, order_status: "sent", delievry_status: "active"}
    //     }
    //     return item
    //   })
    //   setCurrentItems(updatedCurrentItems)
    // }

    let payload = {
      delivery_temperature_celcius: fd.delivery_temperature_celcius,
      details: fd.labo_items.map(item => ({id: item.id, product_quantity: item.product_quantity})),
    }
  
    await request.patch(`/labos/orders/${selectedIngredient.id}`, payload); 
    if(!isSend) {
      await getLaboOrdersData();
      setShowSaveOrderModal(true);
      onHide();
    }
  }

  const onSubmit = async (ev) => {
    ev.preventDefault();
    try {
      if (isRestaurantLoaded && !hasRetaurants) {
        setSampleRestaurantModal(true);
        onHide();
        return;
      }
      setIsSubmitDisabled(true);
      setLoading(true);
      await saveOrder(true)
      await sendOrderHandler();
      setLoading(false);
      onHide();
    } catch (error) {
      console.log(error);
      setIsSubmitDisabled(false);
    }
  };

  const updateOrderItemQty = (idx, value) => {
    if(selectedIngredient.status === "1" || selectedIngredient.status === "0") return
    setFd(prevFd => {
      return {
        ...prevFd,
        labo_items: prevFd.labo_items.map((item, i) => {
          if (i === idx) {
            return {
              ...item,
              product_quantity: value
            }
          }
          return item
        })
      }
    })

  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="add-ingredient-labo labo-order-modal"
      backdropClassName="add-ingredient-backdrop labo-order-modal-backdrop"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
        {!isUploaded && (
          <Modal.Title className="add-restaurants-modal-title title-header">
            <span>{t("Order number")} {selectedIngredient?.order_number}</span>
            <span>
            <span className="input-title ps-0" style={{ fontSize: "12px" }}>{t("Temperature")} (°C)</span>
            <input
              type="number"
              className="form-control custom-input"
              value={fd.delivery_temperature_celcius}
              onChange={(e) => setFd({ ...fd, delivery_temperature_celcius: e.target.value })}
            />
            </span>
          </Modal.Title>
        )}
      </Modal.Header>

      <Modal.Body>
        <Container className="p-0">
          {isUploadedModal && isUploaded && (
            <div className="d-flex justify-content-center flex-column  text-center upload-container">
              <div>
                <img src={UploadedIcon} alt="..." />
              </div>
              <div className="heading mt-5">
                <label>{t("Order uploaded")} !</label>
              </div>
              <div className="subheading mt-2 mb-5">
                <label>
                  {t("Your Order has been successfully uploaded")}.
                </label>
              </div>
              <div>
                <Button className="add-restaurant-confirm-btn" onClick={onHide}>
                  {t("OK")}
                </Button>
              </div>
            </div>
          )}
          {!isUploaded && (
            <Form noValidate className="ps-0 pe-0" onSubmit={onSubmit}>
              <Row className="mt-4">
                  <Col lg={12}>
                    <Card className="mb-0 bg-transparent">
                      <div className="p-4 allergens-container">

                        <div className="row custom-table h-100">
                          <div className="col-lg-12 h-100 p-0">
                            <div className="tablescroll">
                              <table className="table bg-transparent">
                                <thead>
                                  <tr>
                                    <td style={{ width: "25%" }}>
                                    {t("Reference")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "50%" }}
                                    >
                                      {t("ProductName")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "30%" }}
                                    >
                                      {t("Condition")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("UnitCost")}
                                    </td>
                                   
                                    <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("Unit selling price")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("Quantity")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("Total sale price")}
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                    {fd.labo_items?.map((ingredient, index) => (
                                      <tr
                                      className="flex justify-between"
                                    >
                                      <td style={{ height: "50px" }}>
                                        <Text style={{width: "100%", display: "inline-block", whiteSpace: "initial"}}  caption={ingredient?.provider_reference_number} />
                                      </td>
                                      <td className="text-center" style={{ width: "200px" }}>
                                        <Text style={{overflow: "hidden"}} caption={ingredient?.provider_reference_name} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={ingredient?.conditioning} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={ingredient?.unit_cost} />
                                      </td>
                                      {/* <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption="0" />
                                      </td> */}
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={ingredient?.unit_price} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <QuantityContainer currentValue={ingredient?.product_quantity} index={index} setCurrentValue={updateOrderItemQty} />
                                      </td>
                                      {/* <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={ingredient?.product_quantity} />
                                      </td> */}
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={(ingredient?.product_quantity * ingredient?.unit_price)?.toFixed(2)} />
                                      </td>
                                      <td style={{ width: "100px" }} className="text-center">
                                      </td>
                                    </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>

              <Modal.Footer className="labo-order-modal-footer">
                <Button
                  type="button"
                  className="labo-order-modal-btn"
                  style={{ backgroundColor: "#873CFC"}}
                  disabled={selectedIngredient.status === "1" || selectedIngredient.status === "0"}
                  onClick={() => saveOrder()}
                >
                  {t("To safeguard")}
                </Button>
                <Button
                  type="submit"
                  className="labo-order-modal-btn"
                  style={{ backgroundColor: "#873CFC"}}
                  disabled={selectedIngredient.status === "1" || selectedIngredient.status === "0" || selectedIngredient.status === null}
                >
                  {t("Send order")}
                </Button>
                <Button
                  type="button"
                  className="labo-order-modal-btn"
                  onClick={() => {
                    setShowOrderPdfModal(true);
                  }}
                  style={{ backgroundColor: "#873CFC"}}
                >
                  {t("View the order form")}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default CustomModal;
