import { useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import Creatable from "react-select/creatable";
import { useTranslation } from "react-i18next";
import moment from "moment";
import omit from "lodash/omit";
import {
  Modal,
  Button,
  Row,
  Col,
  Card,
  Badge,
  Form,
  Dropdown,
} from "react-bootstrap";

import request from "services/request";
import SelectInput from "components/selectInput";
import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";
import {
  MEAL_CATEGORIES,
  DEFAULT_ERROR_MESSAGE,
  UNITS,
  ITEMS_PER_PAGE,
} from "common/constants";

import { cloneDeep } from "common/utils.ts";
import SelectAsyncPaginate from "components/selectAsyncPaginate/index.tsx";
import AddIngredientModal from "../myIngredients/modal";
import MealEvolution from "./mealEvolution";
import UploadedIcon from "assets/images/uploaded_meal.png";
import Addicon from "assets/images/icon/add_purple.svg";
import FilterIcon from "assets/images/icon/FILTER_ICON1.svg";

import JPEG_ICON from "assets/images/icon/jpeg.svg";
import CLOSE_ICON from "assets/images/icon/DELETE.svg";
import EYE_ICON from "assets/images/icon/eye.svg";
import { withAsyncPaginate } from "react-select-async-paginate";
import convert from "convert";
import { roundToTwo } from "common/utils";
import { useOnDropAccepted } from 'common/utils.ts';
import { getConvertibleUnitOptions, getTimeRange } from "../utils";
import { toast } from "react-toastify";

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

function TextInput({
  type = "text",
  dataField,
  required = true,
  caption,
  ...props
}) {
  return (
    <>
      <span className="input-title ps-0">{caption}</span>
      <input
        type={type}
        name={dataField}
        className="form-control custom-input"
        required={required}
        {...props}
      />
    </>
  );
}

const INGREDIENT_INITIAL = {
  ingredient_id: null,
  ingredient_unit: null,
  ingredient_quantity: null,
  ingredient_cost: 0,
  converted_unit: null,
  converted_quantity: null,
  eat_in: false,
  take_away: false
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const lowerIngredients= [ {
  "carbon_emission": "2kg",
  "ingredient_unit": "item",
  "ingredient_quantity": 1.1,
  "ingredient_cost": 3.7,
  "converted_unit": null,
  "converted_quantity": null,
  "ingredient_id":{
    "id": "542d4da4-450e-472e-b4fd-d9f7922da3b2",
    "name":"POULET ENTER",
    "unit":"kilogram"
  }
},
{
"carbon_emission": "1.5kg",
"ingredient_unit": "item",
"ingredient_quantity": 1,
"ingredient_cost": 0.24,
"converted_unit": null,
"converted_quantity": null,
"ingredient_id":{
  "id": "542d4da4-450e-472e-b4fd-d9f7922da3b2",
  "name":"TENDERS DE POULET HFC  AU KG (HFC)",
  "unit":"item"
}
}]

function CustomModal({ onHide, show, state, onRowAdded, ...props }) {
  const { t } = useTranslation();
  const { setLoading, setError } = useLoading();
  const [ingredientModal, setIngredientModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isCompetitiveset, setIsCompetitiveset] = useState(true);

  const [ingredients, setIngredients] = useState([])
  const [selectedIngredients, setSelectedIngredients] = useState([])
  const [fd, setFd] = useState({
    allergens: [],
    ingredients: [],
    meals: [],
  });
  const prevFdQuantity = useRef();
  const prevMealQuantity = useRef(null);
  const { selectedRestaurantId, setSampleRestaurantModal, isLabo } = useUserData();

  const [formData, setFormData] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD")
  });

  const onApply = (params) => {
    setFormData({ ...params });
  };

  const onDropAccepted = useOnDropAccepted(setFiles, setError, t);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/jpg, image/jpeg, image/png",
    maxFiles: 1,
    onDropAccepted
  });

  const onTimeChange = (selectedTime) => {
   setFd({ ...fd, preparation_time: selectedTime });
  };

  const time_ranges = getTimeRange("00:00", "23:59", 30);

  useEffect(async () => {
    if(selectedRestaurantId) {
    const payload = {
      restaurant_id: selectedRestaurantId,
      limit: 999999999,
      page: 1,
    };
    let response = await request.get("ingredients", payload, true, false);
    setIngredients(response.ingredients);
  }
  }, [selectedRestaurantId]);

  useEffect(() => {
    setFd({
      allergens: [],
      ingredients: [],
      meals: [],
    });
    setFiles([]);
    setIsSubmitDisabled(true);
    setIsUploaded(false);
  }, [show]);

  useEffect(() => {
    if (!state.selectedMeal) {
      setFd({ allergens: [], ingredients: [], servings: "", meals: [] });
      return;
    }
    // if(ingredients.length > 0){
    const newSelectedMeal = cloneDeep(state.selectedMeal);
    prevFdQuantity.current = state.selectedMeal?.format ?? 0;
    let selIngredients = ingredients.filter(a => state?.selectedMeal?.ingredients?.some(b => b.id === a.id));
    setSelectedIngredients((p) => [...p, ...selIngredients])
    const manipulatedIngredients = newSelectedMeal.ingredients
    .filter(ingredient => !ingredient.IngredientMeal?.associated_meal_id)  // Exclude ingredients with associated_meal_details
    .map((ingredient) => {
        return {
          ingredient_id: {
            id: ingredient.id,
            name:
              ingredient.name +
              (ingredient.brand ? ` (${ingredient.brand})` : ""),
              unit_price: parseFloat(ingredient?.cost_excl_tax/(ingredient?.format ?? 1)),
              unit: ingredient.unit,
  
          },
          ingredient_cost: ingredient.IngredientMeal.ingredient_cost,
          ingredient_quantity: ingredient.IngredientMeal.ingredient_quantity,
          ingredient_unit: ingredient?.unit,
          converted_quantity: ingredient.IngredientMeal.converted_quantity,
          carbon_emission:ingredient.IngredientMeal.carbon_emission,
          converted_unit: ingredient.IngredientMeal.converted_unit,
          eat_in: ingredient.IngredientMeal.eat_in === 1 ? true : false,
          take_away: ingredient.IngredientMeal.take_away === 1 ? true : false,
          ...(ingredient.IngredientMeal.converted_quantity === null && {
            converted_quantity: ingredient.IngredientMeal.ingredient_quantity,
          }),
          ...(ingredient.IngredientMeal.converted_unit === null && {
            converted_unit: ingredient.unit,
          }),
        };
      }
    );

  //  Group ingredients by associated meal details
    let groupedMeals = newSelectedMeal.ingredients
      .filter(ingredient => ingredient.IngredientMeal.associated_meal_id) 
      .reduce((acc, ingredient) => {
        const associatedMealId = ingredient.IngredientMeal.associated_meal_id; 
        const associatedMealName = ingredient.IngredientMeal.associated_meal_name; 
        const associatedRecipeUnit = ingredient.IngredientMeal.associated_meal_unit;
        const associatedQuantity = ingredient.IngredientMeal.associated_meal_quantity;

        if (!acc[associatedMealId]) {
          acc[associatedMealId] = {
            id: associatedMealId,
            name: associatedMealName,
            format: associatedQuantity,
            unit : associatedRecipeUnit,
            ingredients: [] 
          };
        } 

        acc[associatedMealId].ingredients.push({
          IngredientMeal: {...ingredient.IngredientMeal, unit_ingredient_quantity: ingredient.IngredientMeal.ingredient_quantity / (ingredient.IngredientMeal.associated_meal_quantity || 1)},
          brand: ingredient.brand,
          cost_excl_tax: ingredient.cost_excl_tax,
          format: ingredient.format,
          id: ingredient.id,
          name: ingredient.name,
          unit: ingredient.unit,
          provider_ingredient_id: ingredient.provider_ingredient_id,
          provider_reference_name: ingredient.provider_reference_name,
          unit: ingredient.unit
        });
        return acc;
      }, {});

    let manipulatedMeals = Object.values(groupedMeals);
    manipulatedMeals = manipulatedMeals.map((meal) => {
      let cost_excl_tax = meal?.ingredients.reduce((a, b) => a + b.IngredientMeal.ingredient_cost, 0);
      return {
        ...meal,
        cost_excl_tax: cost_excl_tax,
        unit_price: parseFloat(cost_excl_tax / meal?.format),
        eat_in: meal?.ingredients[0]?.IngredientMeal?.eat_in === 1 ? true : false,
        take_away: meal?.ingredients[0]?.IngredientMeal?.take_away === 1 ? true : false
      }
    })

    setFd({
      ...fd,
      cost: newSelectedMeal.cost_per_person,
      ...newSelectedMeal,
      ...(newSelectedMeal.ingredients && {
        ingredients: manipulatedIngredients,
      }),
      meals: manipulatedMeals,
      allergens: state.selectedMeal.allergens.map((a) => a.id),
    });
  // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedMeal, ingredients.length]);

  useEffect(() => {
    if (fd.name && fd.type && fd.servings) {
      setIsSubmitDisabled(false);
      return;
    }
    setIsSubmitDisabled(true);
  }, [fd]);

  // useEffect(() => {
  //   setFd({
  //     ...fd,
  //     price_per_person: getSum() / (fd.servings || 1),
  //     margin_per_person: getSum() / (fd.servings || 1) - (getSum() / (fd.servings || 1)),
  //   })
  // }, [fd.ingredients.length, fd.meals.length]);


  const onAllergenClick =
    ({ id }) =>
    () => {
      let newAllergens = cloneDeep(fd.allergens);
      if (newAllergens.includes(id)) {
        newAllergens = newAllergens.filter((allergenId) => allergenId !== id);
      } else {
        newAllergens = [...newAllergens, id];
      }
      setFd({
        ...fd,
        allergens: newAllergens,
      });
    };

  const onSelectionChange =
    (type) =>
    ({ id }) =>
      setFd({ ...fd, [type]: id });

  const onTextChange = ({ target: { name, value, type } }) => {
    let cost = null;
    let newValue = value;
    if (type === "number") {
      newValue = value !== "" ? value.replace(/[^\d.]/, "") : "";
      // if (value !== "") {
      //   return;
      // }
    }
    if (name === "servings") {
      cost = getPerServingCost(null, newValue);
    }

    if(name === 'margin_per_person') {
      setFd({ ...fd, [name]: newValue, price_per_person: +value + (getSum() / (fd.servings || 1)) });
      return
    }

    if(name === 'price_per_person') {
      setFd({ ...fd, [name]: newValue, margin_per_person: value - (getSum() / (fd.servings || 1)) });
      return
    }
    
    setFd({ ...fd, [name]: newValue, ...(cost && { cost }) });
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();

    if (selectedRestaurantId === "") {
      setSampleRestaurantModal(true);
      return;
    }
    try {
      setIsSubmitDisabled(true);
      setLoading(true);
      const { cost, selling_price_per_person, ...rest } = fd;
      let fdIngredients =
        fd.ingredients
          ?.map((i) => {
          return  {
            ...i,
            ingredient_id: i.ingredient_id?.id,
            provider_ingredient_id: selectedIngredients
              .find((item) => item.id === i.ingredient_id?.id)
              ?.details?.find((i) => i.is_default)?.provider_ingredient.id,
          }}
          )
          .filter((i) => i.ingredient_id) || null;
      let mealIngredients = fd.meals
        .map(({ id, ingredients, format, eat_in, take_away }) =>
          ingredients?.length
            ? ingredients?.map((item) => {
                delete item.IngredientMeal.associated_meal_id;
                delete item.IngredientMeal.associated_meal_name;
                delete item.IngredientMeal.associated_meal_unit;
                return {
                  ingredient_id: item.id,
                  provider_ingredient_id: item?.provider_ingredient_id,
                  ...item.IngredientMeal,
                  eat_in: eat_in, 
                  take_away: take_away,
                  ingredient_quantity: item.IngredientMeal.ingredient_quantity,
                  ingredient_cost: (item.IngredientMeal.ingredient_cost),
                  associated_meal_id: id,
                  associated_meal_quantity: format
                };
              })
            : []
        ).flat();
      let ingredients = [...fdIngredients, ...mealIngredients];

      const format =
        fd?.type === "intermediate-recipe" ? parseFloat(fd.format) : undefined;
      const updatedIngredients = ingredients.map(ingredient => ({
        ...ingredient,
       eat_in: ingredient.eat_in === 1 || ingredient.eat_in === true ? true : false,
       take_away: ingredient.take_away === 1 || ingredient.take_away === true ? true : false
      }));

      // Check if all ingredients have either `eat_in` or `take_away` positive
      const hasValidIngredient = updatedIngredients.every(
        (ingredient) => ingredient.eat_in || ingredient.take_away
      );

      if (!hasValidIngredient) {
        setLoading(false);
        setIsSubmitDisabled(false);
        setError(t("Ingredient must be eat in or eat away"));
        return;
      }

      const payload = {
        ...omit(rest, ["value", "label", "meals"]),
        ingredients: updatedIngredients,
        restaurant_id: selectedRestaurantId,
        cost_per_person: +parseFloat(getSum()).toFixed(4) / fd.servings,
        total_cost: +parseFloat(getSum()).toFixed(4),
        ...(isLabo &&{ tax: parseFloat(fd.tax)}),
        // price_per_person: +fd.selling_price_per_person,
        // total_price: +parseFloat(
        //   fd.selling_price_per_person * fd.servings
        // ).toFixed(4),
        price_per_person: isLabo ? fd.price_per_person : +fd.selling_price_per_person || +parseFloat(getSum()).toFixed(4) / fd.servings,
        total_price: isLabo ? (fd.price_per_person * fd.servings) : +parseFloat(fd.selling_price_per_person * fd.servings).toFixed(4) ||(+parseFloat(getSum()).toFixed(4)),
        margin_per_person: isLabo ? fd.margin_per_person : +(selling_price_per_person - (+parseFloat(getSum()).toFixed(4) / fd.servings)) || 0,
        total_margin: isLabo ? (fd.margin_per_person * fd.servings) :  fd.selling_price_per_person && fd.servings
          ? +parseFloat(
              fd.selling_price_per_person * fd.servings - (+parseFloat(getSum()).toFixed(4) / fd.servings) * fd.servings
          ).toFixed(4)
          : 0,
        format,
        unit: fd?.unit,
        reference_number: fd?.reference_number,
      };

      (payload.ingredients || []).forEach((ingredient) => {
        delete ingredient.unit_ingredient_quantity;
        ingredient.converted_quantity = null;
        ingredient.converted_unit = null;
        if (ingredient?.ingredient_unit === ingredient?.converted_unit) {
          ingredient.converted_unit = null;
          ingredient.ingredient_quantity = ingredient.converted_quantity;
          // ingredient.ingredient_quantity = !state.selectedMeal
          //   ? ingredient.converted_quantity
          //   : (parseFloat(fd?.format) / prevFdQuantity.current) *
          //     ingredient.converted_quantity;
          ingredient.converted_quantity = null;
        }
      });

      delete payload?.preparation_time;

      if (!state.selectedMeal) {
        const result = await request.post("/meals", payload);
        if ([200, 201].includes(result.status)) {
          fileUpload(await result.json());
          return;
        }
        throw Error(result);
      }
      delete payload.id;
      delete payload.provider_name;
      delete payload.image_path;
      delete payload.createdAt;
      delete payload.updatedAt;
      delete payload.item_id;
      delete payload.is_external;

      const resultQuery = await request.patch(
        `/meals/${state.selectedMeal.id}`,
        payload
      );

      if(ingredients.some(ingredient => ingredient.provider_ingredient_id === null)) {
        toast.warn(t("Some ingredients has missing Provider ingredients, Please Update your ingredints"))
      }
      if ([200].includes(resultQuery.status)) {
        fileUpload(await resultQuery.json(), false);
        return;
      }
      const err = await resultQuery.json()
      setLoading(false);
      toast.error(err.msg)

      // throw Error(resultQuery);
    } catch (error) {
      console.log(error);
      setIsSubmitDisabled(false);
    }
  };

  const fileUpload = async (meal, create = true) => {
    if (!acceptedFiles.length) {
      onRowAdded(meal, create);
      setFd({ ...fd, allergens: [] });
      setLoading(false);
      setIsSubmitDisabled(false);
      setIsUploaded(true);
      return;
    }
    try {
      let formData = new FormData();
      formData.append("image", acceptedFiles[0]);
      await request.patchFile(`/meals/upload-image/${meal.id}`, formData);

      setIsUploaded(true);
      onRowAdded(meal, create);
      setLoading(false);
      setFd({ ...fd, allergens: [] });
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    } finally {
      setIsSubmitDisabled(false);
    }
  };

  const onRemoveFile = (file, i) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const onAddIngredientItem = (ev) => {
    setFd({
      ...fd,
      ingredients: [
        ...(fd.ingredients || []),
        {
          ...INGREDIENT_INITIAL,
          ...(ev.id && {
            ingredient_id: {
              id: ev.id,
              name: ev.name,
              unit_price: ev.unit_price,
              unit: ev.unit,
              carbon_emission: ev.carbon_emission
            },
          }),
        },
      ],
    });
  };

  const calculateCost = (ingredient) => {
    const { unit_price, unit } = ingredient?.ingredient_id || {};
    const {
      ingredient_unit,
      ingredient_quantity,
      converted_unit,
      converted_quantity,
    } = ingredient;
    if (["g", "kg", "mg", "lb", "l", "ml", "m", "mm"].includes(ingredient_unit)) {
      try {
        const convertQuantity = converted_quantity ?? ingredient_quantity;
        const convertFrom = converted_unit ?? ingredient_unit;
        const convertTo = unit ?? converted_unit ?? ingredient_unit;
        const convertedValue =
          convert(convertQuantity, convertFrom).to(convertTo) || 0;

        ingredient.ingredient_cost = (parseFloat(unit_price * convertedValue)).toFixed(4);
        ingredient.ingredient_quantity = roundToTwo(convertedValue);
        return ingredient
      } catch (error) {
        ingredient.ingredient_cost = roundToTwo(
          ingredient_quantity * unit_price || 0
        );
        return ingredient
      }
    } else {
      ingredient.ingredient_cost = roundToTwo(
        ingredient_quantity * unit_price || 0
      );
      return ingredient
    }
  };

  const onIngredientTextBoxChange =
    (i) =>
    ({ target: { name, value: val } }) => {
      let value = +val.replace(/[^\d.]/, "");

      if (!(+value !== 0) && val !== "") {
        return;
      }
      if (val === "") {
        value = "";
      }

      if (name === "meal_quantity") {
        let selectedMeal = fd.meals[i];
        prevMealQuantity.current = selectedMeal.format;
        selectedMeal = {...selectedMeal, format: value, cost_excl_tax: selectedMeal.unit_price * value} 
        // prevFdQuantity.current =
        //   fd.meals[i]?.format > 0 ? fd.meals[i]?.format : 1;
        let updatedIngredients = fd.meals[i].ingredients.map((item) => {
          // let  providerIngredient = ingredients.find(ing => ing.id === item.id)
          // let ratio =
          //   value === ""
          //     ? 0 / prevFdQuantity.current
          //     : +value / prevFdQuantity.current;
          return {
            ...item,
            IngredientMeal: {
              ...item.IngredientMeal,
              // ingredient_quantity: item.IngredientMeal.unit_ingredient_quantity * value,
              // ingredient_cost: parseFloat(item.cost_excl_tax * (value * item.IngredientMeal.ingredient_quantity))
              // ingredient_cost: parseFloat(item.cost_excl_tax / item.format * (item.IngredientMeal.unit_ingredient_quantity * value)),
              ingredient_quantity: (item.IngredientMeal.ingredient_quantity / prevMealQuantity.current || item.format) * value,
              ingredient_cost: parseFloat((item.IngredientMeal.ingredient_cost/ (prevMealQuantity.current || item.format)) * value)
            },
          };
        });
        prevFdQuantity.current = parseFloat(value);
        fd.meals[i].format = value;
        selectedMeal.ingredients = updatedIngredients;
        const total = updatedIngredients.reduce(
          (acc, item) => {
            let cost = item.IngredientMeal.ingredient_cost ? item.IngredientMeal.ingredient_cost : 0
            return acc + cost
          } , 0)
          selectedMeal = {...selectedMeal, cost_excl_tax: total};
        fd.meals[i] = selectedMeal;
        return setFd({ ...fd });
      }

      let newIngredients = cloneDeep(fd.ingredients);
      const ingredient = newIngredients[i];
      ingredient[name] = value;
      let providerIngredient;
      if (!state.selectedMeal) {
        providerIngredient = selectedIngredients[i]?.details?.find(
          (i) => i?.is_default
        )?.provider_ingredient;
      } else {
        providerIngredient = {
          ...state.selectedMeal.ingredients[i],
          price_excl_tax: fd.ingredients[i].ingredient_cost,
          recipe_unit_quantity: fd.ingredients[i].ingredient_quantity,
        };
      }

      if (name === "ingredient_quantity") {
        ingredient["converted_quantity"] = value;
        ingredient["ingredient_cost"] = providerIngredient?.provider_ingredient_id !== null ? (parseFloat(value * (providerIngredient?.price_excl_tax / providerIngredient?.recipe_unit_quantity))).toFixed(4) || 0 : (parseFloat(providerIngredient?.cost_excl_tax * ingredient?.ingredient_quantity))?.toFixed(4);
      }
      // const provider_ingredient = ingredients.find(ing => ing.id === ingredient.ingredient_id.id)?.details.find(i => i.is_default).provider_ingredient
    
      const updatedIngredient = calculateCost({...ingredient, ingredient_id: { ...ingredient.ingredient_id, unit_price: parseFloat(providerIngredient?.price_excl_tax/providerIngredient?.recipe_unit_quantity) }});
      newIngredients = newIngredients.map(i => {
            if (i.ingredient_id.id === updatedIngredient.ingredient_id.id) {
             return {...updatedIngredient}
            }
            return i
          })

      const cost = getPerServingCost(newIngredients);
      setFd({ ...fd, cost, ingredients: newIngredients });
    };

  const onMealSelectBoxChange = (i) => (type) => (newMealSelected) => {
    if (newMealSelected.ingredients.length) {
      const mealsIngredients = cloneDeep(newMealSelected.ingredients);
      const manipulatedIngredients = mealsIngredients.map((ingredient) => {
        setSelectedIngredients(p => [...p, ...newMealSelected.ingredients])
        return {
          ingredient_id: {
            id: ingredient.id,
            name:
              ingredient.name +
              (ingredient.brand ? ` (${ingredient.brand})` : ""),
            unit_price: ingredient.format ? parseFloat(ingredient.cost_excl_tax / ingredient.format) : 0,
            unit: ingredient.unit,
          },
          ingredient_cost: ingredient.format ? parseFloat(parseFloat(ingredient.cost_excl_tax / ingredient.format) * ingredient.IngredientMeal.ingredient_quantity)?.toFixed(4) : 0,
          cost_excl_tax: ingredient.format ? parseFloat(parseFloat(ingredient.cost_excl_tax / ingredient.format) * ingredient.IngredientMeal.ingredient_quantity)?.toFixed(4) : 0,
          ingredient_quantity: ingredient.IngredientMeal.ingredient_quantity,
          ingredient_unit: ingredient.IngredientMeal.ingredient_unit,
          converted_quantity: ingredient.IngredientMeal.converted_quantity,
          converted_unit: ingredient.IngredientMeal.converted_unit,
          ...(ingredient.IngredientMeal.converted_quantity === null && {
            converted_quantity: ingredient.IngredientMeal.ingredient_quantity,
          }),
          ...(ingredient.IngredientMeal.converted_unit === null && {
            converted_unit: ingredient.IngredientMeal.ingredient_unit,
          }),
        };
      });

      let cost = fd.cost_per_person ? fd.cost_per_person : 0;
      // cost = fd.servings ? cost * fd.servings : cost;
      manipulatedIngredients.map((i) => (cost += parseFloat(i.cost_excl_tax)));
      // cost = fd.servings ? cost / fd.servings : cost;

      if (type === "meals") {
        if (!newMealSelected.format) newMealSelected.format = 1;
          newMealSelected = {
            ...newMealSelected, 
            cost_excl_tax :(newMealSelected?.ingredients.reduce((a, b) => a + b.IngredientMeal.ingredient_cost, 0) / newMealSelected.servings),
            // cost_excl_tax: newMealSelected.cost_per_person, 
            unit_price: newMealSelected.cost_per_person/newMealSelected.format, 
            ingredients: newMealSelected.ingredients.map(item => ({...item, cost_excl_tax: item.cost_excl_tax / item.format }))
          }
          const newIngredients = newMealSelected.ingredients.map(ingredient => ({
            ...ingredient, 
            IngredientMeal: {
              ...ingredient.IngredientMeal, 
              ingredient_cost: ingredient.IngredientMeal.ingredient_cost / newMealSelected.servings, 
              ingredient_quantity: ingredient.IngredientMeal.ingredient_quantity / newMealSelected.servings, 
              unit_ingredient_quantity: ingredient.IngredientMeal.ingredient_quantity / (ingredient.IngredientMeal.associated_meal_quantity || 1) 
            },
        }))
        prevMealQuantity.current = fd;
        setFd((prevFd) => ({
          ...prevFd,
          meals: [...prevFd.meals, {...newMealSelected, ingredients: newIngredients}],
        }));
        return;
      }

      setFd((prevFd) => ({
        ...prevFd,
        cost,
        ingredients:
          prevFd.ingredients === null
            ? manipulatedIngredients
            : prevFd.ingredients.concat(manipulatedIngredients),
        associated_meal_id: newMealSelected.id
      }));
    } else {
      setFd((prevFd) => ({
        ...prevFd,
        meals: [...fd.meals, newMealSelected]
      }));
    }
  };
  const onIngredientSelectBoxChange =
    (i) =>
    (type) =>
    ({ id, unit_price, name, unit }) => {
      let newIngredients = cloneDeep(fd.ingredients);
      const alreadyExist = newIngredients.find(item => item.ingredient_id?.id === id)
      if(type === 'ingredient_id' && alreadyExist) {
        newIngredients.splice(i, 1)
        setFd(prev => ({...prev, ingredients: newIngredients }))
        toast.warn(t("Ingredient already exists!"))
        return
      }
      const ingredient = newIngredients[i];

      setSelectedIngredients((prev) => [
        ...prev,
        ingredients.find((i) => i.id === id),
      ]);

      if (type !== "ingredient_unit") {
        ingredient[type] = id;
      }
      if (type === "ingredient_unit") {
        ingredient["converted_unit"] = id;
        //ingredient["converted_quantity"] = ingredient?.ingredient_quantity;
        /* ingredient["ingredient_quantity"] = roundToTwo(
          convert(
            ingredient?.ingredient_quantity,
            ingredient?.converted_unit
          ).to(ingredient?.ingredient_unit) || 0
        ); */
      }
      let updatedFd = {}
      if (type === "ingredient_id") {
        ingredient[type] = { id, name, unit_price, unit };
        ingredient["ingredient_unit"] = unit;
        ingredient["converted_unit"] = unit;
        ingredient["ingredient_quantity"] = 1;
        ingredient["converted_quantity"] = 1;
        const selectedIngredient = ingredients.find((item) => item.id === ingredient.ingredient_id.id)
        const providerIngreidnet = selectedIngredient?.details.find((i) => i.is_default)?.provider_ingredient
        // if(!providerIngreidnet) return
        ingredient.ingredient_id["unit_price"] = providerIngreidnet ? parseFloat(selectedIngredient?.cost_excl_tax / providerIngreidnet?.recipe_unit_quantity) : selectedIngredient?.cost_excl_tax > 0 ? selectedIngredient.cost_excl_tax : 0 
        updatedFd = {
          ...fd,
          price_per_person: fd.price_per_person + parseFloat(ingredient.ingredient_id.unit_price),
        }
        // if(state.selectedMeal) {
        //   let providerIngredient = ingredients
        //   .find((item) => item.id === id)
        //   .details.find((i) => i.is_default).provider_ingredient;
        // state.selectedMeal.ingredients = [
        //   ...state.selectedMeal.ingredients,
        //   {
        //     ...providerIngredient,
        //     cost_excl_tax: providerIngredient.price_excl_tax,
        //     format: providerIngredient.recipe_unit_quantity,
        //   },
        // ];
        // }
      }

      const updatedIngredient = calculateCost({...ingredient});
      newIngredients = newIngredients.map(i => {
            if (i.ingredient_id.id === updatedIngredient.ingredient_id.id) {
             return {...updatedIngredient}
            }
            return i
          })

      const cost = getPerServingCost(newIngredients);
      setFd({ ...fd, ...updatedFd, cost, ingredients: newIngredients });
    };

  const deleteIngredient = (index, type) => () => {
    if (type === "meal") {
      fd.meals.splice(index, 1);
      return setFd({ ...fd });
    }

    const newIngredients = cloneDeep(fd.ingredients);
    newIngredients.splice(index, 1);
    // setSelectedIngredients((prev) => prev.filter((item) => item.id !== ingredient.ingredient_id.id));
    const cost = getPerServingCost(newIngredients);
    setFd({ ...fd, cost, ingredients: newIngredients });
  };

  const deleteLowerIngredient = (index, type) => () => {
    if (type === "meal") {
      fd.meals.splice(index, 1);
      return setFd({ ...fd });
    }

    const newLowerIngredients = cloneDeep(lowerIngredients);
    newLowerIngredients.splice(index, 1);
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="..." />
      </div>
    </div>
  ));

  const getSum = (ingredients = null) => {
    let fdTotal = (ingredients || fd.ingredients || []).reduce(
      (previousValue, currentValue) =>
        previousValue + parseFloat(currentValue?.ingredient_cost || 0),
      0
    );
    const mealTotal = fd?.meals
      .map((meal) => {
        const ingredients = meal?.ingredients || [];
        return ingredients.reduce(
          (previousValue, currentValue) =>
            previousValue +
            parseFloat(currentValue.IngredientMeal.ingredient_cost),
          0
        );
      })
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  
    return fdTotal + mealTotal;
  };

  // Calculate the total cost of "eat_in" ingredients
  const getSumEatIn = (ingredients = null) => {
    let fdTotal = (ingredients || fd.ingredients || []).reduce(
      (previousValue, currentValue) => {
        if (currentValue.eat_in) {
          return previousValue + parseFloat(currentValue.ingredient_cost || 0);
        }
        return previousValue; 
      },0
    );
    // Calculate the total meal cost for "eat_in" meals
    const mealTotal = fd.meals.reduce(
      (previousValue, meal) => {
        if (meal.eat_in) {
          return previousValue + (parseFloat(meal?.cost_excl_tax) || 0);
        }
        return previousValue; 
      },0
    );
    return fdTotal + mealTotal;
  };
  
    // Calculate the total cost of "take_away" ingredients
    const getSumEatAway = (ingredients = null) => {
    let fdTotal = (ingredients || fd.ingredients || []).reduce(
      (previousValue, currentValue) => {
        if (currentValue.take_away) {
          return previousValue + parseFloat(currentValue.ingredient_cost || 0);
        }
        return previousValue; 
      },
      0
    );
    // Calculate the total meal cost for "take_away" meals
    const mealTotal = fd.meals.reduce(
      (previousValue, meal) => {
        if (meal.take_away) {
          return previousValue + (parseFloat(meal?.cost_excl_tax) || 0);
        }
        return previousValue; 
      },0
    );
    return fdTotal + mealTotal;
  };
  
  const getPerServingCost = (ingredients, servings = null) => {
    return (
      parseFloat(
        (getSum(ingredients || []) / (servings || fd.servings)).toFixed(4)
      ) || 0
    );
  };

  const onAddIngredient = () => {
    setIngredientModal(true);
  };

  const loadOptions = async (search) => {
    if (!search || search.length < 3 || search === "") {
      return { options: [], hasMore: false };
    }

    try {
      const response = await request.get(
        "meals",
        { search, is_external: false, restaurant_id: selectedRestaurantId },
        true,
        false
      );

      return {
        options: response.meals.map((i) => ({
          ...i,
          value: i.name,
          label: i.name,
        })),
        hasMore: false,
      };
    } catch (error) {
      console.log(error);
      return { options: [], hasMore: false };
    }
  };

  const createSelectChange = async (obj, { action }) => {
    if (action === "select-option") {
      props.setSelectedMeal(obj);
    }

    if (action === "clear") {
      props.setSelectedMeal(null);
    }

    if (action === "create-option") {
      setFd({ ...fd, name: obj.value });
    }
  };

  const getColorBasedOnCarbonEmission = (carbonEmission) => {
    if (carbonEmission && carbonEmission.includes("kg")) {
        const emissionInKg = parseInt((carbonEmission.split("kg"))[0]);
        if (emissionInKg < 10) {
            return "#FF0000";
        }
    } else if (carbonEmission && carbonEmission.includes("g")) {
        const emissionInG = parseInt((carbonEmission.split("g"))[0]);
        if (emissionInG <= 50) {
            return "#F9C70C"; 
        } else if (emissionInG < 100) {
            return "#008000";
        }
    }
    return "black";
  };
  
  const handleCheckboxChange = (i, field) => (event) => {
    const { checked } = event.target;
    const updatedIngredients = [...fd.ingredients];
    updatedIngredients[i] = {
      ...updatedIngredients[i],
      [field]: checked
    };
    setFd({ ...fd, ingredients: updatedIngredients });
  };

  const handleMealCheckboxChange = (i, field) => (event) => {
    const { checked } = event.target;
    const updatedMeals = [...fd.meals];
    updatedMeals[i] = {
      ...updatedMeals[i],
      [field]: checked
    };
    setFd({ ...fd, meals: updatedMeals });
  };

  // const percentage = 74;
  // const radius = 50;
  // const circumference = Math.PI * radius;
  // const progress = (74 / 100) * circumference;
  
  return (
    <>
      <AddIngredientModal
        show={ingredientModal}
        onHide={() => {
          setIngredientModal(false);
          prevMealQuantity.current = null
        }}
        data={{ allergens: state.allergens, providers: state.providers }}
        onRowAdded={(ingredientItem, a) => {
          onAddIngredientItem(ingredientItem);
        }}
        isUploadedModal={false}
      />
      <div
        show={show}
        onHide={onHide}
        size="lg"
        centered
        className="add-meals"
      >
        <div className="meal-back-button" onClick={onHide}>
          {"<"} {t("Back")}
        </div>
        <div className="meals-model">
          {!isUploaded && (
            <div className="meal-divider">
              <ul className="navbtns mb-0">
                <li className={`${isCompetitiveset ? "active" : ""}`}>
                  <button
                    className={`nav-link ${isCompetitiveset ? "active" : ""}`}
                    onClick={() => setIsCompetitiveset(true)}
                  >
                    {state.selectedMeal ? t("Edit") : t("Add")} {t("Meal")}(s)
                  </button>
                </li>
                {state.selectedMeal && (
                  <li className={`${!isCompetitiveset ? "active" : ""}`}>
                    <button
                      className={`nav-link ${!isCompetitiveset ? "active" : ""}`}
                      onClick={() => setIsCompetitiveset(false)}
                    >
                      {t("Product price evolution")}
                    </button>
                  </li>
                )}
              </ul>
            </div>
          )}
        {/* 
          {(!isFilterShown && !isCompetitiveset) && (
            <div className="headerbtns ms-3">
              <button
                onClick={() => setFilterShown(true)}
                className="btn btn-white btn-icon btn-theme"
                style={{
                  height:"35px"
                }}
              >
                <img src={FilterIcon} alt="" className="m-0" />
              </button>
            </div>
          )} */}

        </div>
        
        {!isCompetitiveset && (
          <>
            <MealEvolution/>
            {/* <MealEvoluationRightSide/> */}
          </>
        )}

        { isCompetitiveset && (
        <Modal.Body>
          <div className="p-0">
            {isUploaded && (
              <Modal show={isUploaded} onHide={onHide} centered>
                <Modal.Body>
                  <div className="d-flex justify-content-center flex-column  text-center upload-container">
                    <div>
                      <img src={UploadedIcon} alt="..." />
                    </div>
                    <div className="heading mt-5">
                      <label>Meal uploaded !</label>
                    </div>
                    <div className="subheading mt-2 mb-5">
                      <label>Your meal has been successfully uploaded.</label>
                    </div>
                    <div>
                      <Button
                        className="add-restaurant-confirm-btn"
                        onClick={onHide}
                      >
                        OK
                      </Button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            )}

            {/* {!isUploaded && ( */}
            <Form className="ps-0 pe-0" noValidate onSubmit={onSubmit}>
              <Row className=" d-flex justify-content-between">
                <Col lg={fd.is_external ? 3 : 4}>
                  <span className="add-restaurants-input-title ps-0">
                    {t("MealName")}
                  </span>
                  <CreatableAsyncPaginate
                    value={fd?.name ? { label: fd.name, value: fd.name } : ""}
                    loadOptions={loadOptions}
                    onChange={createSelectChange}
                    placeholder={t("MealName")}
                    isClearable
                    className="meal-name-select"
                  />
                </Col>
                <Col lg={fd.is_external ? 3 : 4} className="input-border">
                  <span className="input-title ps-0">{t("MealType")}</span>
                  <SelectInput
                    dataField="type"
                    placeholder={t("SelectMealType")}
                    options={MEAL_CATEGORIES.slice(1).map((m) => ({
                      ...m,
                      label: t(m.label),
                    }))}
                    onChange={onSelectionChange}
                    value={
                      MEAL_CATEGORIES.find(({ id }) => id === fd?.type) ??
                      null
                    }
                  />
                </Col>
                <Col lg={fd.is_external ? 3 : 4}>
                  <span className="input-title ps-0">{t("Servings")}</span>
                  <TextInput
                    dataField="servings"
                    caption=""
                    placeholder={t("AddServings")}
                    onChange={onTextChange}
                    value={fd?.servings}
                    type="number"
                    min={0}
                  />
                </Col>
                {
                  fd?.is_external === true && (
                    <Col lg={3}>
                      <TextInput
                        dataField="selling_price_per_person"
                        caption={`${t("SellingPrice")} / ${t("person")}`}
                        placeholder={t("SellingPrice")}
                        onChange={onTextChange}
                        value={fd?.selling_price_per_person}
                        type="number"
                        min={0}
                      />
                    </Col>
                  )
                }
              </Row>

              <Row style={{ paddingTop: "10px" }}>
                <Col lg={fd.is_external ? 3 : 4}>
                  <TextInput
                    dataField="format"
                    caption={`${t("Format")}`}
                    placeholder={t("Format")}
                    onChange={onTextChange}
                    value={fd?.format}
                    type="number"
                    min={0}
                  />
                </Col>
                <Col lg={fd.is_external ? 3 : 4} className="input-border">
                  <span className="input-title ps-0">{t("Unit")}</span>
                  <SelectInput
                    dataField="unit"
                    placeholder={t("SelectUnit")}
                    options={UNITS.slice(1).map((r) => ({
                      ...r,
                      label: t(r.label),
                    }))}
                    onChange={onSelectionChange}
                    value={UNITS.find(({ id }) => id === fd?.unit)}
                  />
                </Col>
                <Col lg={fd.is_external ? 3 : 4}>
                  <TextInput
                    dataField="reference_number"
                    caption={`${t("ReferenceNumber")}`}
                    placeholder={t("ReferenceNumber")}
                    onChange={onTextChange}
                    value={fd?.reference_number}
                    type="text"
                  />
                </Col>
              </Row>

              {isLabo && <Row style={{ paddingTop: "10px" }}>
                <Col lg={4}>
                  <span className="input-title ps-0">{t(`${t("SellingPrice")} / ${t("person")}`)}</span>
                    <TextInput
                      dataField="price_per_person"
                      caption=""
                      placeholder={t("SellingPrice")}
                      onChange={onTextChange}
                      value={fd?.price_per_person}
                      type="number"
                      min={0}
                    />
                </Col>
                <Col lg={4}>
                    <span className="input-title ps-0">{t("Margin / person")}</span>
                      <TextInput
                        dataField="margin_per_person"
                        caption=""
                        placeholder={t("AddMargin")}
                        onChange={onTextChange}
                        value={fd?.margin_per_person}
                        type="number"
                        min={0}
                      />
                </Col>
                <Col lg={4}>
                    <span className="input-title ps-0">{t("Tax")}</span>
                      <TextInput
                        dataField="tax"
                        caption=""
                        placeholder={t("AddTax")}
                        onChange={onTextChange}
                        value={fd?.tax}
                        type="number"
                        min={0}
                      />
                </Col>
              </Row>}

              <Row style={{ paddingTop: "10px" }}>
                <Col lg={4}>
                    <span className="add-restaurants-input-title ps-0">
                      {t("PreparationTime")} (hh:mm)
                    </span>
                    <Col lg={{ span: 12 }}>
                      <Dropdown className="ps-0 pe-0" onSelect={onTimeChange}>
                        <Dropdown.Toggle variant="button" id="dropdown-basic" style={{ height: "43px", borderRadius: "10px" }} className="dropdown-toggle btn-icon restaurant-dropdown">
                          {fd?.preparation_time ?? t('SelectTime')}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="type-dropdown-menu">
                          {time_ranges.map((option) => (
                            <Dropdown.Item class="form-check weekdays-dropdown-item" key={option} eventKey={option}>
                              {option}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                </Col>
              </Row>

              <Row>
                <Col lg={9}>
                  <Row className="mt-4">
                    <Col lg={12}>
                      <span className="input-title ps-0">
                        {t("Ingredients")}/{t("Meals")}
                      </span>
                      <Card className="mb-0">
                        <div className="p-4 allergens-container">
                          <div className="row custom-table h-100">
                            <div className="col-lg-5 h-100 p-2 attach-a-pos">
                              <SelectAsyncPaginate
                                dataField="meals"
                                placeholder={state?.selectedMeal?.is_external ? t("AttachARecipe") : t("AttachAPOS")}
                                value={t("SelectIngredientsByMeal")}
                                query="meals"
                                isMyMeals
                                isMulti={false}
                                isExternal={!state?.selectedMeal?.is_external}
                                onChange={onMealSelectBoxChange()}
                              />
                            </div>
                          </div>

                          <div className="row custom-table h-100">
                            <div className="col-lg-12 h-100 p-0">
                              <div className="tablescroll">
                                <table className="table meal-ingredients-table">
                                  <thead>
                                    <tr>
                                      <td style={{ width: "55%", fontWeight: "bold"}}>
                                        {t("Ingredients")}/{t("Meals")}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ width: "35%", fontWeight: "bold" }}
                                      >
                                        {t("Unit")}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ width: "35%", fontWeight: "bold" }}
                                      >
                                        {t("Quantity")}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ width: "20%", textAlign: "end", fontWeight: "bold" }}
                                      >
                                        {t("Cost")}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ width: "20%", textAlign: "end", fontWeight: "bold" }}
                                      >
                                        {t("Eat in")}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ width: "20%", textAlign: "end", fontWeight: "bold" }}
                                      >
                                        {t("Eat away")}
                                      </td>
                                      {selectedRestaurantId === "" &&      
                                          <td
                                            className="text-center"
                                            style={{ width: "40%", textAlign: "end", fontWeight: "bold" }}
                                          >
                                            {t("Carbon - Emission")}
                                          </td>
                                      }
                                      <td
                                        style={{
                                          width: "50px",
                                          textAlign: "end",
                                          fontWeight: "bold"
                                        }}
                                      ></td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {fd.meals.map((currentMeal, i) => (
                                      <tr
                                        key={currentMeal.id}
                                        className="flex justify-between meal-ing-list"
                                      >
                                        <td style={{ height: "50px" }}>
                                          <SelectAsyncPaginate
                                            dataField="meal_id"
                                            placeholder={t("SelectIngredient")}
                                            value={currentMeal}
                                            query="meals"
                                            isMulti={false}
                                            onChange={onIngredientSelectBoxChange(
                                              currentMeal.id
                                            )}
                                            mapper={(rows) =>
                                              rows.map((row) => ({
                                                ...row,
                                                name: row.name,
                                              }))
                                            }
                                          />
                                        </td>
                                        <td style={{ width: "200px" }}>
                                          <TextInput
                                            dataField="ingredient_unit"
                                            disabled
                                            onChange={onTextChange}
                                            value={t(currentMeal?.unit)}
                                            type="text"
                                          />
                                        </td>
                                        <td style={{ width: "100px" }}>
                                          <TextInput
                                            dataField="meal_quantity"
                                            placeholder="quantity"
                                            name="meal_quantity"
                                            onChange={onIngredientTextBoxChange(
                                              i
                                            )}
                                            value={currentMeal.format?? 1}
                                            type="number"
                                          />
                                        </td>
                                        <td className="text-center">
                                          {currentMeal?.cost_excl_tax?.toFixed(4)}
                                        </td>
                                        {/* eat in checkboxes and eat away checkboxes for meals */}
                                        <td className="text-center">
                                          <input
                                            type="checkbox"
                                            dataField="eat_in"
                                            checked={currentMeal.eat_in || false}
                                            onChange={handleMealCheckboxChange(i, 'eat_in')}
                                          />
                                        </td>
                                        <td className="text-center">
                                          <input
                                            type="checkbox"
                                            dataField="take_away"
                                            checked={currentMeal.take_away || false}
                                            onChange={handleMealCheckboxChange(i, 'take_away')}
                                          />
                                        </td>
                                        <td className="p-0">
                                          <button
                                            type="button"
                                            onClick={deleteIngredient(i, `meal`)}
                                            className="table-delete-btn p-0"
                                          >
                                            <img src={CLOSE_ICON} alt="" />
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                    {(fd.ingredients || []).map(
                                      (ingredient, i) => (
                                        <tr key={i} className="meal-ing-list">
                                          <td style={{ height: "50px" }}>
                                            <SelectAsyncPaginate
                                              dataField="ingredient_id"
                                              placeholder={t("SelectIngredient")}
                                              value={ingredient.ingredient_id}
                                              isMulti={false}
                                              query="ingredients"
                                              onChange={onIngredientSelectBoxChange(
                                                i
                                              )}
                                              mapper={(rows) =>
                                                rows.map((row) => ({
                                                  ...row,
                                                  name:
                                                    row.name +
                                                    (row.brand
                                                      ? ` (${row.brand})`
                                                      : ""),
                                                }))
                                              }
                                            />
                                          </td>
                                          <td className="">
                                            <SelectInput
                                              portal={true}
                                              dataField="ingredient_unit"
                                              placeholder={t("SelectUnit")}
                                              options={getConvertibleUnitOptions(ingredient?.converted_unit || ingredient?.ingredient_unit)}
                                              onChange={onIngredientSelectBoxChange(
                                                i
                                              )}
                                              value={UNITS.find(
                                                ({ id }) =>
                                                  id ===
                                                  (ingredient.converted_unit ||
                                                    ingredient.ingredient_unit)
                                              )}
                                            />
                                          </td>
                                          <td className="">
                                            <TextInput
                                              dataField="ingredient_quantity"
                                              placeholder="format"
                                              onChange={onIngredientTextBoxChange(
                                                i
                                              )}
                                              value={
                                                ingredient?.converted_quantity ||
                                                ingredient?.ingredient_quantity
                                              }
                                              type="number"
                                            />
                                          </td>
                                          <td className="text-center">
                                            {!isNaN(ingredient?.ingredient_cost) ? ingredient?.ingredient_cost : null}
                                          </td>
                                          <td className="text-center">
                                            <input
                                              type="checkbox"
                                              dataField="eat_in"
                                              checked={ingredient.eat_in || false}
                                              onChange={handleCheckboxChange(i, 'eat_in')}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <input
                                              type="checkbox"
                                              dataField="take_away"
                                              checked={ingredient.take_away || false}
                                              onChange={handleCheckboxChange(i, 'take_away')}
                                            />
                                          </td>
                                          {selectedRestaurantId === "" && (<td className="text-center" style={ {width:"30% !important"}}>
                                            {!isNaN((ingredient?.ingredient_cost / getSum()) * 100) ? ((ingredient?.ingredient_cost / getSum()) * 100).toFixed(4) : null}
                                          </td>
                                          )}
                                          {selectedRestaurantId === "" && (
                                            <td className="text-center" 
                                            style={{
                                                fontSize:"12px",
                                                color: getColorBasedOnCarbonEmission(ingredient.carbon_emission)
                                            }}>
                                                {ingredient.carbon_emission ? `${ingredient.carbon_emission} CO2-eq` : null}
                                            </td>
                                            )}
                                          <td className="p-0">
                                            <button
                                              type="button"
                                              onClick={deleteIngredient(i)}
                                              className="table-delete-btn p-0"
                                            >
                                              <img src={CLOSE_ICON} alt="" />
                                            </button>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              <div className="bottom-container d-flex justify-content-between">
                                <div>
                                  <Button
                                    variant="add-ingredient"
                                    onClick={onAddIngredientItem}
                                  >
                                    <img
                                      src={Addicon}
                                      className="add-btn-icon"
                                      alt="..."
                                    />
                                    {t("Add")}
                                  </Button>
                                  <Button
                                    variant="add-ingredient add-ingredient-modal"
                                    onClick={onAddIngredient}
                                    className="ms-3"
                                  >
                                    <img
                                      src={Addicon}
                                      className="add-btn-icon"
                                      alt="..."
                                    />
                                    {t("AddIngredient")}
                                  </Button>
                                </div>
                                {Boolean(getSum()) && (
                                  <div className="total-cost-container">
                                    {/* <div className="total-cost-text">
                                      {t("TotalCost")}
                                    </div>
                                    <div className="total-cost">
                                      {getSum().toFixed(4)} EUR
                                    </div> */}
                                    <div style={{ display: "flex", gap: "20px" }}>
                                      <div className="total-eatIn-cost">
                                        <div className="total-cost-text">
                                          {t("Eat in")} {t("Cost")}
                                        </div>
                                        <div className="total-cost">
                                          {getSumEatIn().toFixed(4)} EUR
                                        </div>
                                      </div>
                                      <div className="total-eatIn-cost">
                                        <div className="total-cost-text">
                                          {t("EatAwayCost")}
                                        </div>
                                        <div className="total-cost">
                                          {getSumEatAway().toFixed(4)} EUR
                                        </div>
                                      </div>
                                    </div>
                                    {/* {Boolean(fd.servings && getSum()) && (
                                      <div className="pre-serving">
                                        {(getSum() / fd.servings).toFixed(4)} EUR
                                        / {t("servings")}
                                      </div>
                                    )} */}
                                    {selectedRestaurantId === "" && (
                                      <div className="pre-serving" style={{
                                        color:"#008000",
                                        fontSize:"20px",
                                        fontWeight:"600"
                                      }}>
                                        2.5 g CO2-eq
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  {state.selectedMeal && selectedRestaurantId === "" && (
                    <Row className="mt-4">
                      <Col lg={12}>
                        <span className="input-title ps-0">
                          {t("Ingredients")}/{t("Meals")}
                        </span>
                        <Card className="mb-0">
                          <div className="p-4 allergens-container">
                            <div className="row custom-table h-100">
                              <div className="col-lg-12 h-100 p-0">
                              <div>
                                <h3 style={{
                                  color:"#000000",
                                  fontSize:"21px",
                                  fontWeight:"700",
                                  paddingBottom:"17px"
                              }}>{t("lowerIngredients")}</h3>
                              </div>
                                <div className="tablescroll">
                                  <table className="table meal-ingredients-table">
                                    <thead>
                                      <tr>
                                        <td style={{ width: "55%", fontWeight: "bold"}}>
                                          {t("Ingredients")}/{t("Meals")}
                                        </td>
                                        <td
                                          className="text-center"
                                          style={{ width: "35%", fontWeight: "bold" }}
                                        >
                                          {t("Unit")}
                                        </td>
                                        <td
                                          className="text-center"
                                          style={{ width: "35%", fontWeight: "bold" }}
                                        >
                                          {t("Quantity")}
                                        </td>
                                        <td
                                          className="text-center"
                                          style={{ width: "20%", textAlign: "end", fontWeight: "bold" }}
                                        >
                                          {t("Cost")}
                                        </td>
                                        {selectedRestaurantId === "" &&      
                                            <td
                                              className="text-center"
                                              style={{ width: "40%", textAlign: "end", fontWeight: "bold" }}
                                            >
                                              {t("Carbon - Emission")}
                                            </td>
                                        }
                                        <td
                                          style={{
                                            width: "50px",
                                            textAlign: "end",
                                            fontWeight: "bold"
                                          }}
                                        ></td>
                                      </tr>
                                    </thead>
                                    <tbody className="meal-ingredients">
                                      {(lowerIngredients || []).map(
                                        (ingredient, i) => {
                                          return (
                                          <tr key={i}>
                                            <td style={{ height: "50px" }}>
                                              <SelectAsyncPaginate
                                                dataField="ingredient_id"
                                                placeholder={t("SelectIngredient")}
                                                value={ingredient.ingredient_id}
                                                isMulti={false}
                                                query="ingredients"
                                                onChange={onIngredientSelectBoxChange(
                                                  i
                                                )}
                                                mapper={(rows) =>
                                                  rows.map((row) => ({
                                                    ...row,
                                                    name:
                                                      row.name +
                                                      (row.brand
                                                        ? ` (${row.brand})`
                                                        : ""),
                                                  }))
                                                }
                                              />
                                            </td>
                                            <td className="">
                                              <SelectInput
                                                portal={true}
                                                dataField="ingredient_unit"
                                                placeholder={t("SelectUnit")}
                                                options={getConvertibleUnitOptions(ingredient?.converted_unit || ingredient?.ingredient_unit)}
                                                onChange={onIngredientSelectBoxChange(
                                                  i
                                                )}
                                                value={UNITS.find(
                                                  ({ id }) =>
                                                    id ===
                                                    (ingredient.converted_unit ||
                                                      ingredient.ingredient_unit)
                                                )}
                                              />
                                            </td>
                                            <td className="">
                                              <TextInput
                                                dataField="ingredient_quantity"
                                                placeholder="format"
                                                onChange={onIngredientTextBoxChange(
                                                  i
                                                )}
                                                value={
                                                  ingredient?.converted_quantity ||
                                                  ingredient?.ingredient_quantity
                                                }
                                                type="number"
                                              />
                                            </td>
                                            <td className="text-center">
                                            {(ingredient?.ingredient_cost) || null}
                                            </td>
                                            {selectedRestaurantId === "" && (
                                              <td className="text-center" 
                                              style={{
                                                  fontSize:"12px",
                                                  color: getColorBasedOnCarbonEmission(ingredient.carbon_emission)
                                              }}>
                                                  {ingredient.carbon_emission ? `${ingredient.carbon_emission} CO2-eq` : null}
                                              </td>
                                              )}
                                            <td className="p-0">
                                              <button
                                                type="button"
                                                onClick={deleteLowerIngredient(i)}
                                                className="table-delete-btn p-0"
                                              >
                                                <img src={CLOSE_ICON} alt="" />
                                              </button>
                                            </td>
                                          </tr>
                                          )
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </Col>

                <Col lg={3}>
                  <Row className="mt-4 photo-container">
                    <Col lg={12}>
                      <span className="input-title ps-0">{t("Photo")}</span>
                      <div className="">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <p>{t("Drag&Drop")}</p>
                        </div>
                        <aside>
                          <span className="input-title m-0">
                            {t("AcceptedFiles")}
                          </span>
                          <div className="files-list">
                            <ul>
                              {files.map((file, i) => (
                                <li
                                  key={i}
                                  className="d-flex justify-content-between"
                                >
                                  <div className="d-flex">
                                    <img src={JPEG_ICON} alt="..." />
                                    <p className="ps-3">
                                      <span>{file.path}</span>
                                      <span className="size">
                                        {parseInt(file.size * 0.001)} Kb
                                      </span>
                                    </p>
                                  </div>
                                  <div>
                                    <img
                                      src={EYE_ICON}
                                      alt="..."
                                      className="eye me-3"
                                    />
                                    <img
                                      src={CLOSE_ICON}
                                      alt="..."
                                      className="close"
                                      onClick={onRemoveFile(file, i)}
                                    />
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </aside>
                        <aside style={thumbsContainer}>{thumbs}</aside>
                        {fd.image_path && !files.length && (
                          <div style={thumb}>
                            <div style={thumbInner}>
                              <img src={fd.image_path} style={img} alt="..." />
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  
                  {/* Food cost */}
                  {/* <Row>
                    <Card className="food-cost-card shadow-sm">
                      <Card.Body className="text-center">
                        <h6 className="food-cost-title">Food Cost</h6>
                        <div className="gauge-container">
                          <svg width="120" height="70" viewBox="0 0 120 70">
                            <defs>
                              <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="0%" stopColor="#873CFC" />
                                <stop offset="100%" stopColor="#C54CFC" />
                              </linearGradient>
                            </defs>
                            
                            <path
                              d="M 10 60 A 50 50 0 1 1 110 60"
                              fill="none"
                              stroke="#ddd"
                              strokeWidth="10"
                            />
                            <path
                              d="M 10 60 A 50 50 0 1 1 110 60"
                              fill="none"
                              stroke="url(#gradient)"
                              strokeWidth="10"
                              strokeDasharray={`${progress} 157`} 
                              strokeLinecap="round"
                            />
                          </svg>

                          <div className="gauge-text">{percentage}%</div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Row> */}
                </Col>
              </Row>

              <Row className="mt-4">
                <Col lg={12}>
                  <span className="input-title ps-0">{t("Allergens")}</span>
                  <Card className="mb-0">
                    <div className="p-4 allergens-container">
                      {state.allergens.map((allergen, key) => (
                        <Badge
                          key={key}
                          pill
                          className={`allergens-badge ${
                            fd.allergens.includes(allergen.id) ? "active" : ""
                          }`}
                          onClick={onAllergenClick(allergen)}
                        >
                          {t(allergen.name)}
                        </Badge>
                      ))}
                    </div>
                  </Card>
                </Col>
              </Row>

              <Modal.Footer className="add-restaurants-modal-footer">
                <Button
                  type="submit"
                  className="add-restaurant-confirm-btn"
                  disabled={isSubmitDisabled}
                >
                  {t("Confirm")}
                </Button>
              </Modal.Footer>
            </Form>
            {/* )} */}
          </div>
        </Modal.Body>
        )}
      </div>
    </>
  );
}

export default CustomModal;
