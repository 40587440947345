import React, { useState, useEffect, useReducer, useRef, useMemo } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { set } from "lodash";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap"
import CustomTable from "components/customTable/index.tsx";
import { useUserData } from "contexts/AuthContextManagement";
import { useLoading } from "contexts/LoadingContextManagement";
import { cloneDeep, getTimezoneFormatUtc } from "common/utils.ts";

import {
  DEFAULT_ERROR_MESSAGE,
  ITEMS_PER_PAGE,
} from "common/constants";
import { getRandomNumber } from "views/occupancy/data";
import UploadModal from "../../stock/uploadModal";
import useFetch from "customHooks/useFetch";
import sampleStockPred from "../../../data/labo_stock.json";
import stockProviders from "../../../data/stock_provider.json"
import reducer, { ACTION_TYPES, initialState } from "../../stock/reducer";
import request from "services/request";
import SortFilter from "components/customTable/sortFilter";
import FilterIcon from "assets/images/icon/FILTER_ICON1.svg";

function LeftSide({ formData, clear }) {
  const tableDataClone = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isStocksLevelDown, setIsStockLevelDown] = useState(false);
  const { setLoading, setError } = useLoading();
  const { t } = useTranslation();
  const {
    selectedRestaurant,
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    stockEvolution: stockEvolutionData,
    resetStock,
    setFilterShown,
    isFilterShown 
  } = useUserData();
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [forecastingLoading, setForecastingLoading] = useState(false);
  const [orderDetailsModalShow, setOrderDetailsModalShow] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [sleaveState, setSleaveState] = useState({
    index: -1,
    isOpen: true,
  });
  const [fd, setFd] = useState({
    orderBy: "desc",
    sortBy: "stock.unit_stock",
  });
  const [stockValue, setStockValue] = useState([])
  const [filteredTableDataWithTotal, setFilteredTableDataWithTotal] = useState(
    []
  );
  const [activeProvider, setActiveProvider] = useState("select_provider");

  const { data: providersData } = useFetch(`providers`, {
    restaurant_id: selectedRestaurantId,
  });

  const [providersMap, providersArr] = useMemo(() => {
    const dataSource = hasRetaurants ? providersData : stockProviders;
    if (dataSource?.providers) {
      const prMap = dataSource.providers.reduce(
        (obj, current) => ({ ...obj, [current.id]: current.name }),
        { select_provider: t("select_provider") }
      );
      const prArr = [
        { id: "select_provider", name: t("select_provider") },
        ...dataSource.providers,
      ];
      return [prMap, prArr];
    }
    return [{}, []];
  }, [providersData, stockProviders]);

  const processedCurrentItems = useMemo(() => {
    const newCurrentItems = [...currentItems];
    if (sleaveState.index !== -1) {
      newCurrentItems[sleaveState.index] = {
        ...newCurrentItems[sleaveState.index],
        prediction: { isOpen: sleaveState.isOpen },
        start_date: formData.start_date,
        end_date: formData.end_date,
        time_zone: selectedRestaurant?.timezone,
      };
    }
    return newCurrentItems;
  }, [currentItems, sleaveState, resetStock]);

  useEffect(() => {
    const newData = [...processedCurrentItems];
    setFilteredTableDataWithTotal(newData);
  }, [processedCurrentItems]);

  const formatData = ({ ingredient_stock, isHist = false }, isDummy = false) => {

    return (ingredient_stock ?? []).map((i) => {
      const getName = () => i.name + (i.brand ? ` (${i.brand})` : "");
      const getQty = (n) => Number((n >= 0 ? n : 0).toFixed(2));
      const prevision = i?.stock_prediction?.prevision ?? 0
    
      if (isDummy) {
        i.stock.unit_stock = i?.stock?.unit_stock;
      } 

      let inventory = 0;
      let theoretical_stock = 0;
      let stock_gap = 0;

      let out_unit_total = 0;
      let in_unit_total = 0;
      let loss = 0;
 
      if (isHist) {
          out_unit_total = i.stock_log?.out_unit_total?? 0;
          in_unit_total = i.stock_log?.in_unit_total?? 0;
          loss = (i.stock_log ? (i.stock_log.in_unit_total - i.stock_log.out_unit_total)?.toFixed(2) : 0);
        } else {
          inventory = i.stock?.unit_stock?? 0;
          theoretical_stock = i.stock?.theoretical_stock?? 0;
          stock_gap = i?.stock ? (i?.stock?.unit_stock - i?.stock?.theoretical_stock)?.toFixed(2) : 0;
      }
    
      return {
        ...i,
        name: getName(),
        ...(i.stock === null && { stock: { unit_stock: 0 } }),
        ...(!i.qty_to_buy && {
          qty_to_buy: prevision
            ? getQty(prevision - (i?.stock?.unit_stock ?? 0))
            : 0,
        }),
        product_quantity: prevision
          ? getQty(
              Math.ceil((prevision - (i?.stock?.unit_stock ?? 0)) / i.format)
            )
          : 0,
        out_unit_total,
        in_unit_total,
        loss,

        stock: {
          unit_stock: inventory
        },
        theoretical_stock,
        stock_gap,
        ...(!i.stock_prediction && {
          stock_prediction: { prevision },
        }),
      };
    });
  };

  const fetchAndSetOrder = async () => {
    try {
      let res = await request.get(`labos/restaurants`, {labo_id: selectedRestaurantId});
      let restaurants = res.restaurants.map(res => res.id)
      restaurants = formData.restaurants.length ? formData.restaurants : restaurants
      const result = await getOrders(restaurants);
      setCurrentItems(result.meals);
      setPageCount(result.total_pages);
      setCurrentPage(result.page - 1);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    fetchAndSetOrder();
  }, [JSON.stringify(formData)])

  const getOrders = (restaurants = [], page = 1) => {
    const comparisonDates = formData?.comparison_start_date && formData?.comparison_end_date
    ? getTimezoneFormatUtc(formData.comparison_start_date, formData.comparison_end_date, selectedRestaurant?.timezone)
    : {};

    const payload = {
      labo_id : selectedRestaurantId,
      restaurants,
      start_date: getTimezoneFormatUtc(formData.start_date, formData.end_date, selectedRestaurant?.timezone)?.start_date,
      end_date: getTimezoneFormatUtc(formData.start_date, formData.end_date, selectedRestaurant?.timezone)?.end_date,
      ...(comparisonDates.start_date && { comparison_start_date: comparisonDates.start_date }),
      ...(comparisonDates.end_date && { comparison_end_date: comparisonDates.end_date }),
      limit: ITEMS_PER_PAGE,
      page,
      order_by: fd.orderBy.toUpperCase(),
      ...(fd.sortby && { sort_by: fd.sortby }),
      ...(fd.search && { search: fd.search }),
    };
    return request.get(`labos/sales-forecasting`, payload);
  };

  // Invoke when user click to request another page.
  const handlePageClick = async (ev) => {
    try {
      if (isRestaurantLoaded && !hasRetaurants) {
        const resultDummyData = getSampleStocks(ev.selected + 1);
        setForecastingLoading(false);
        generateTableData(formatData(resultDummyData));
        setPageCount(resultDummyData.total_pages);
        setCurrentPage(ev.selected);
        return;
      }
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const generateSalesForecastColumns = () => {
    // Determine the label based on formData values
    let periodLabel = "";
    if (formData?.year) {
      periodLabel = "Year-1";
    } else if (formData?.month) {
      periodLabel = "Month-1";
    } else if (formData?.week) {
      periodLabel = "Week-1";
    }

    return [
      {
        caption: t("Name"),
        dataField: "name",
        type: "string",
        headerStyle: {
          width: "120px",
          fontWeight: "700",
          textAlign: "center",
          fontSize: "19px",
        },
        style: { fontSize: "21px", fontWeight: "600", color: "#292D32", textAlign: "center" },
        active: false,
        type: "customRender",
        render: (column, item) => {
          const { name } = item;
          return (
            <div style={{display: "flex", flexDirection: "column"}}>
              <span className="main-value actual-value forecast-name">{name}</span>
              <span className="period-label">{t(periodLabel)}</span>
            </div>
          );
        },
      },
      {
        caption : <div>
          <div>{t("TOTAL")}</div>
          <div>{t("Realised")}</div>
        </div>,
        dataField: "total_realised",
        type: "number",
        style: {textAlign: "center"},
        headerClassName: "text-center actual-header",
        headerStyle: {
          fontWeight: "600",
          textAlign: "center",
          fontSize: "19px",
          whiteSpace: "pre-line"
        },
        active: true,
        type: "customRender",
        render: (column, item) => {
          // Find the restaurant data for this specific row
          const total_actual_sales = item?.sales_by_restaurants
            .map((rest) => rest.actual_sales || 0)
            .reduce((a, b) => a + b, 0);
          
          const total_comparison_sales = item?.sales_by_restaurants
            .map((rest) => rest.comparison_sales || 0)
            .reduce((a, b) => a + b, 0);
    
          const percentageChange = ((total_actual_sales - total_comparison_sales) / (total_comparison_sales || 1)) * 100;
          const displayPercentage = Math.round(percentageChange);

          const percentageColor = total_actual_sales >= total_comparison_sales ? "#8ACC0B" : "#FF4D4D";
    
          return (
            <div>
              <span className="main-value actual-value" style={{ fontSize: "17px", fontWeight: "bold" }}>
                {total_actual_sales}
              </span>
              <div style={{ display: "flex", justifyContent: "center", gap: "14px" }}>
                <div style={{ color: percentageColor, display: "flex", alignItems: "center" }}>
                  <span className="value">{displayPercentage}%</span>
                </div>
                <div style={{ color: percentageColor }}>
                  <span className="value">{total_comparison_sales}</span>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        caption : <div>
        <div>{t("TOTAL")}</div>
        <div>{t("Forecast")}</div>
      </div>,
        dataField: "total_forecast",
        type: "number",
        style: {textAlign: "center"},
        headerClassName: "text-center predicted-header",
        headerStyle: {
          fontWeight: "600",
          textAlign: "center",
          fontSize: "19px",
          whiteSpace: "pre-line"
        },
        active: true,
        type: "customRender",
        render: (column, item) => {
          const total_predicted_sales = item?.sales_by_restaurants.map((rest) => rest.predicted_sales).reduce((a, b) => a + b, 0);
          return (
            <div style={{ fontSize: "17px", fontWeight: "600", color: "#873CFC" }}>
              <div>{total_predicted_sales}</div>
            </div>
          );
        }
      }
    ];
  }

  const generateRestaurantsColumns = () => {
    // Extract unique restaurant names from all items
    const restaurantNames = new Set();
  
    currentItems.forEach(item => {
      item.sales_by_restaurants.forEach(restaurant => {
        restaurantNames.add(restaurant.restaurant_name);
      });
    });
  
    return Array.from(restaurantNames).map((restaurantName) => ({
      dataField: restaurantName.replace(/\s+/g, "_"),
      caption: restaurantName,
      className: "text-center",
      headerClassName: "text-center",
      headerStyle: {
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "18px",
        color: "#000000",
        height: "85px",
        display: ""
      },
      type: "customRender",
      render: (column, item) => {
        // Find the restaurant data for this specific row
        const restaurantData = item.sales_by_restaurants.find(rest => rest.restaurant_name === restaurantName);
  
        if (!restaurantData) return;
  
        const { sales, comparison_sales } = restaurantData;
  
        // Calculate percentage change safely
        const percentageChange = ((sales - comparison_sales) / (comparison_sales || 1)) * 100;
        const displayPercentage = Math.round(percentageChange);

        const percentageColor = sales >= comparison_sales ? "#8ACC0B" : "#FF4D4D";
  
        return (
          <div>
            <span className="main-value actual-value" style={{ fontSize: "17px", fontWeight: "bold", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
              {sales}
            </span>
            <div style={{ display: "flex", justifyContent: "center", gap: "14px" }}>
              <div style={{ color: percentageColor, display: "flex", alignItems: "center" }}>
                <span className="value">{displayPercentage}%</span>
              </div>
              <div style={{ color: percentageColor }}>
                <span className="value">{comparison_sales}</span>
              </div>
            </div>
          </div>
        );
      },
    }));
  };
  
  useEffect(() => {
    setSleaveState((p) => ({ ...p, index: -1, isOpen: false }));
  }, [fd, selectedRestaurantId, currentPage, activeProvider]);

  useEffect(() => {
    if (true) {
      setFd((prevFd) => ({ ...prevFd, sortBy: "product" }));
    }
  }, [true])

  useEffect(() => {
    if (clear) {
      setFd({
        orderBy: "desc",
        sortBy: "stock.unit_stock",
      })
      setActiveProvider('select_provider')
    }
  }, [clear])

  const getSampleInitialData = () => {
    const resultDummyData = getSampleStocks();
    setForecastingLoading(false);
    generateTableData(formatData(resultDummyData));
    setPageCount(resultDummyData.total_pages);
    setCurrentPage(0);
  }

  useEffect(() => {
    if(isRestaurantLoaded && !hasRetaurants) {
      getSampleInitialData()
    }
  },[hasRetaurants, isRestaurantLoaded])

  const getSampleStocks = (page = 1) => {
    const DUMMY_DATA_PER_PAGE = 15;
    const offset = page * DUMMY_DATA_PER_PAGE - DUMMY_DATA_PER_PAGE;
    const sortKeys = {
      good: 3,
      soon: 2,
      no_expiry: 1,
      expired: 0,
    };

    const resultTotal = sampleStockPred
      .sort((a, b) => {
        const [first, second] = fd.sortBy.split(".");
        let aVal = a[first];
        let bVal = b[first];
        if (aVal && second) {
          aVal = aVal[second];
        }
        if (bVal && second) {
          bVal = bVal[second];
        }
        bVal = sortKeys[bVal] || bVal;
        aVal = sortKeys[aVal] || aVal;
        return fd.orderBy === "desc" ? bVal - aVal : aVal - bVal;
      })
      .filter((item) => {
        let res = true;
        // if (status?.length > 0) {
        //   if (item.stock) {
        //     res = status.includes(item.stock.expiry);
        //   } else {
        //     res = false;
        //   }
        // }
        // if (formData.products.length && res) {
        //   res = formData.products.includes(item.id);
        // }
        return res;
      });

    let result = resultTotal
      .filter(
        (item, index) =>
          index >= offset && index <= DUMMY_DATA_PER_PAGE + offset
      )
      .map((item) => {
        const start_date = moment(formData?.start_date).subtract(1, "day");
        const end_date = moment(formData?.end_date);
        const prediction = item.stock_prediction
          .filter(
            (pred) =>
              start_date.isSame(moment(pred.start_date)) ||
              end_date.isSame(moment(pred.end_date)) ||
              start_date.isBetween(
                moment(pred.start_date),
                moment(pred.end_date)
              )
          )
          .reduce(
            (total, num) => total + num.prevision || 0,
            getRandomNumber(0, 8)
          );
        return {
          ...item,
          stock_prediction: { prevision: Number(prediction.toFixed(2)) },
        };
      });
    if (page == 1) {
      result = result.splice(0, 13);
    } else if (page == 2) {
      result = result.splice(13, 26);
    }
    return {
      ingredient_stock: result,
      sort_by: fd.sortBy === "product" ? "name" : fd.sortBy,
      order_by: fd.orderBy.toUpperCase(),
      limit: DUMMY_DATA_PER_PAGE,
      page,
      total_pages: 2,
      total_results: resultTotal.length,
    };
  };

  const generateTableData = (resultData) => {
    tableDataClone.current = resultData;
    setCurrentItems(() => [...resultData]);
  };

  const onSearchChange = (ev) => {
    // setfd({ ...fd, search: ev.target.value });
    if(ev.target.value) {
      setFd({ ...fd, search: ev.target.value });
      const updatedCurrentItems = sampleStockPred.filter((i) => i.name.toLowerCase().includes(ev.target.value.toLowerCase()))
      setCurrentItems([...updatedCurrentItems])
    }else {
      getSampleInitialData()
    }
  };

  let getSortedItems = (val) => {
    setCurrentItems(currentItems.sort((a, b) => a[val] - b[val]));
  };

  useEffect(() => {
    if (stockEvolutionData) {
      let selectStock = processedCurrentItems?.find(
        (i) => i?.id == stockEvolutionData?.ingredient_id
      );
      setIsStockLevelDown(
        stockEvolutionData?.evolution?.some((i) => {
          if (i.stock < selectStock?.min_stock || i.stock <= 0) {
            return true;
          }
        })
      );
    }
  });

  let selecteSalesColumns = generateSalesForecastColumns();
  let selectedRestaurantColumns = generateRestaurantsColumns();

  return (
    <div style={{ width: "100%" }}>
      <Row style={{ marginBottom: "20px" }}>
        <Col className="d-flex justify-content-end">
          <input
            type="search"
            className="search-input me-3"
            placeholder={t("Search")}
            onChange={onSearchChange}
          />

          <SortFilter
            cols={generateSalesForecastColumns()}
            fd={fd}
            setFd={setFd}
            getSortedItems={getSortedItems}
            rootClassName="sort-filter ps-3"
          />
          {!isFilterShown && (
            <div className="headerbtns ms-3">
              <button
                onClick={() => setFilterShown(true)}
                className="btn btn-white btn-icon btn-theme"
                style={{
                  height:"35px"
                }}
              >
                <img src={FilterIcon} alt="" className="m-0" />
              </button>
            </div>
          )}
        </Col>
      </Row>

      <div className="leftcontent leftcontent-stock sales-forecast">
        <div style={{ backgroundColor: "transparent"}} className="card m-10 mb-0">
          {forecastingLoading && (
            <div className="w-100 d-flex justify-content-center card-spinner-container">
              <Spinner animation="border" variant="primary" />
            </div>
          )}

          {!forecastingLoading &&
            <div className="card-body">
              <div className="sales-forecast-wrapper">
                <div className="sales-flex-wrapper">
                  <div style={{ width: !currentItems?.length || currentItems.some(item => !item.sales_by_restaurants?.length) ? "100%" : "50%" }}>
                    <CustomTable columns={selecteSalesColumns} data={currentItems}/>
                  </div>
                  
                  <div style={{ width: currentItems?.length || currentItems.some(item => item.sales_by_restaurants?.length) ? "50%" : "0%" }}>
                    <CustomTable columns={selectedRestaurantColumns} data={currentItems}/>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default LeftSide;
