import React, { useEffect, useRef, forwardRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DatePicker from "react-datepicker";
import { get } from "lodash";
import { Form, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";


import { CustomtableProps } from "./interface";

import NO_IMAGE_ICON from "assets/images/default-image-icon.png";
import AddIcon from "assets/images/add.png";
import UpdateIcon from "assets/images/icon/eye.svg";
import PrevIcon from "assets/images/prev.svg";
import NextIcon from "assets/images/next.svg";

import "./index.scss";
import Sleave from "./sleave";
import NumberInput from "./InputNumber";
import useCurrencySymbol from "customHooks/useCurrencySymbol";

const CustomTable = forwardRef(({
  columns,
  data,
  tableScrollClass,
  deleteRow = () => {},
  selectChange,
  selectedProducts = [],
  selectAllProducts,
  onInputChange,
  selectedKey = "id",
  onRowSelectChanged,
  header = true,
  onUpdateRow,
  onReportRow,
  lessQtyProducts = [],
  onPickupDateChange,
  isPickuptDateOpen,
  onRowDoubleClick = () => {},
  SleaveContent,
  stockEvolutionData,
  isStocksLevelDown,
  negativeTargetValues,
  tableName,
  pageName
}: CustomtableProps, ref: any) => {
  const { t } = useTranslation();
  const headRef: any = useRef(null);

  const currency = useCurrencySymbol();
  const tableRef1 = useRef<HTMLTableElement>(null); // Ref for the dailyOccupancy1 table

  const [isScrolledLeft, setIsScrolledLeft] = useState(false);
  const [isScrolledRight, setIsScrolledRight] = useState(false);

  const isSelectAllProducts = (index) => {
    return Boolean(selectAllProducts) && index === 0;
  }

  const renderTooltip = (value) => (props) =>
    (
      <Tooltip id="button-tooltip" {...props}>
        {value}
      </Tooltip>
    );

  const renderRow = (it, ii) => (
    <tr
      key={ii}
      id={`${it?.id}`}
      className={`${
        isStocksLevelDown &&
        stockEvolutionData?.ingredient_id == it.id
      }  
      
      ${
        lessQtyProducts.includes(get(it, selectedKey))
          ? "less_qty-tr"
          : selectedProducts.includes(get(it, selectedKey))
          ? "selected-tr"
          : ""
      }`}
      style={{
        ...((pageName === "labo" || pageName === "meals") && tableName !== "sales-forecast" && {
          backgroundColor: "white",
          borderRadius: "37px",
          boxShadow: "0px 1px 6px 0px #00000040",
        }),
      }}
      // REMOVE TOTAL ROW
      onDoubleClick={() =>
        onRowDoubleClick(ii, Boolean(data[ii].prediction), it?.id)
      }
    >
      {columns.map((c: any, i) => (
        <td
          key={i}
          className={`${c.className ?? ""} ${
            (c.isLower && c?.isLower(it[c.dataField])) ?? ""
          } ${(c.isHigher && c?.isHigher(it[c.dataField])) ?? ""}`}
          style={{
            fontSize: tableName === "stocks" ? "17px" : (tableName === "dailyOccupancy" || tableName === "dailyOccupancy1") ? "17px" : "14px",
            height: tableName === "stocks" ? "70px" : "40px",
            whiteSpace: c.dataField === "name" ? "initial" : "",
            ...((tableName === "dailyOccupancy" || tableName === "dailyOccupancy1") && { borderBottom: "1px solid #EEEEEE", backgroundColor: "white", fontWeight : "500" }),
            ...c.style,
            // color: (c.dataField === 'predicted_sales' && negativeTargetValues?.some(item => item.id == it.id)) ? "red" : (c.dataField === 'predicted_sales' && data?.some(item => item.id == it.id)) ? "green" : "",
            width:
              i === 0 && it.prediction && headRef.current
                ? headRef.current.offsetWidth - 1
                : c.style?.width,
            ...((pageName === "labo" || pageName === "meals") && tableName !== "sales-forecast" &&
              i === 0 && {
                borderTopLeftRadius: "37px",
                borderBottomLeftRadius: "37px",
                backgroundColor: "white",
              }),
            ...((pageName === "labo" || pageName === "meals") && tableName !== "sales-forecast" &&
              i === columns.length - 1 && {
                borderTopRightRadius: "37px",
                borderBottomRightRadius: "37px",
                backgroundColor: "white",
              }),
          }}
        >
          {isSelectAllProducts(i) && (
            <div className="d-flex align-items-center">
              {it.name !== "Total" && (
                <>
                {(tableName !== "production-planning" && tableName !== 'sales-forecast') && <Form.Group className="me-3">
                  <Form.Check
                    type="checkbox"
                    onChange={selectChange(it)}
                    checked={selectedProducts.includes(get(it, selectedKey))}
                    onDoubleClick={(e) => e.stopPropagation()}
                  />
                </Form.Group>}
                {(tableName == "labo-order" || tableName == 'production-planning') && (
                    <img
                      src={
                        it?.image_path
                          ? it.image_path
                          : NO_IMAGE_ICON
                      }
                      className="cursor-pointer"
                      alt="..."
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "23px",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </>
              )}
              {get(it, c.dataField) && (
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip(get(it, c.dataField))}
                >
                  <span
                    className={`${
                      Boolean(data[ii].prediction)
                        ? "lowStockColor"
                        : !Boolean(data[ii].prediction)
                    }`}
                  >
                    {get(it, c.dataField)}
                  </span>
                </OverlayTrigger>
              )}
            </div>
          )}

          {!(isSelectAllProducts(i) && get(it, c.dataField)) && (
            <>
              {c.type === "dropdown" && (
                <Dropdown
                  onSelect={onRowSelectChanged(c, it)}
                  style={{ position: "inherit" }}
                  className=""
                >
                  <Dropdown.Toggle
                    variant="link"
                    className="btn btn-white dropdown-toggle btn-icon"
                    style={{
                      color: "inherit",
                      minWidth: "115px",
                    }}
                    onDoubleClick={(e) => e.stopPropagation()}
                  >
                    {c.elem &&
                      c.elem(
                        (c.options?.find(
                          (o) => o.value === get(it, c.dataField)
                        )?.label ||
                          get(it, c.dataField)) ??
                          `Select ${c.caption}`
                      )}
                    {!c.elem &&
                      (c.options?.find((o) => o.value === get(it, c.dataField))
                        ?.label ||
                        get(it, c.dataField))}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className="dropdown-menu-custom"
                    style={{ inset: "unset !important" }}
                  >
                    {c.options?.map((d, i) => (
                      <Dropdown.Item key={i} eventKey={d.id} style={d.style}>
                        {d.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {c.columnType === "inputNumber" && (
                <NumberInput
                  c={c}
                  it={it}
                  isTotalRow={it.name === "Total"}
                  currency={currency}
                  onInputChange={onInputChange}
                />
              )}
              {c.columnType === undefined &&
                !["dropdown", "customRender"].includes(c.type) && (
                  <>
                    {i === 0 && (
                      <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip(get(it, c.dataField))}
                      >
                        <span>{get(it, c.dataField, "")}</span>
                      </OverlayTrigger>
                    )}
                    {(tableName === 'sales-forecast' && i !== 0) ? <div>
                        <div style={{fontSize: "23px"}}>{i !== 0 && get(it, c.dataField, "")}</div>
                        <div style={{display: 'flex', justifyContent: 'space-around', fontSize: "12px", color: "#8ACC0B"}}><span>^17%</span><span>130</span></div>
                    </div> :(i !== 0 && get(it, c.dataField, ""))}
                    
                  </>
                )}
              {c.type === "customRender" && c.render(c, it)}

              {c.dataField === "action" && c.type === undefined && (
                <button onClick={deleteRow(it)} className="table-delete-btn">
                  <img src={AddIcon} alt="" />
                </button>
              )}

              {c.dataField === "action" && c.type === "edit" && (
                <button
                  onClick={() => onUpdateRow(it)}
                  className="table-delete-btn"
                >
                  <img src={UpdateIcon} alt="" />
                </button>
              )}

              {c.dataField === "action" &&
                c.type === "dynamic" &&
                c.elem(c, it)}

              {c.type === "datapicker" && (
                <DatePicker
                  selected={Date.parse(it.pickup_date)}
                  dateFormat="dd MMM yyyy"
                  placeholderText={t("Start date")}
                  onChange={onPickupDateChange(it)}
                  className="date-picker"
                  open={isPickuptDateOpen}
                  minDate={moment().toDate()}
                />
              )}
              {c.dataField === "report" && (
                <button
                  onClick={() => onReportRow(it)}
                  className="table-delete-btn"
                >
                  <a href="#">{t("Report a problem")}</a>
                </button>
              )}
            </>
          )}
        </td>
      ))}
    </tr>
  );
  
  const handleScroll = (direction) => {
    if (tableRef1.current) {
      const scrollAmount = direction === 'next' ? 200 : -200; // Adjust scroll amount as needed
      tableRef1.current.scrollLeft += scrollAmount;
      checkScrollPosition(); // Check position after scroll
    }
  };

  const checkScrollPosition = () => {
    if (tableRef1.current) {
      const scrollLeft = tableRef1.current.scrollLeft;
      const maxScrollLeft = tableRef1.current.scrollWidth - tableRef1.current.clientWidth;
      setIsScrolledLeft(scrollLeft === 0);
      setIsScrolledRight(scrollLeft === maxScrollLeft);
    }
  };
  
  return (
    <div className="row custom-table h-100">
      <div 
        className={`col-lg-12 h-100 ${
          (pageName === "labo" || pageName === "meals") ? "labo-orders-table" : ""
        }`}
      >

        {tableName === "dailyOccupancy1" && (
          <div className="scroll-buttons">
            <div>
              <button
                onClick={() => handleScroll("prev")}
                className={`scroll-button prev-button ${isScrolledLeft ? "disabled" : ""}`}
                disabled={isScrolledLeft}
              >
                <img src={PrevIcon} alt="Prev-icon" />
              </button>
            </div>
            <div>
              <button
                onClick={() => handleScroll("next")}
                className={`scroll-button next-button ${isScrolledRight ? "disabled" : ""}`}
                disabled={isScrolledRight}
              >
                <img src={NextIcon} alt="Next-icon" />
              </button>
              </div>
          </div>
        )}

        <div className={`tablescroll ${tableName === "dailyOccupancy1" ? "hide-horizontal-scroll" : ""} ${tableName === 'my-orders' ? "custome-order-table" : ""}`} ref = {tableRef1} style={{ ...tableScrollClass }}>
          <table ref={ref} className="table" style={{
            ...((pageName === "labo" || pageName === "meals") && tableName !== "sales-forecast" && {
              borderSpacing: "0px 17px",
              padding: "0px 10px"
            }),
            ...((pageName === "labo" || pageName === "meals") && {
              backgroundColor: "transparent",            
            })
          }}>
            {header && (
              <thead>
                <tr>
                  {columns?.map((c, i) => (
                    <th
                      key={i}
                      style={{
                        ...c.headerStyle,
                        ...((tableName === "dailyOccupancy" || tableName === "dailyOccupancy1") && { borderBottom: "1px solid rgba(99, 83, 234, 0.3)" }),
                        color: (pageName === "labo" || pageName === "meals") ? '#B5B7C0' : "inherit",
                        width:
                          c.dataField === "name"
                            ? "180px"
                            : c.dataField === "format"
                            ? "100px"
                            : (c.dataField === "action" && pageName === "labo")
                            ? "50px"
                             : (c.dataField === "action_status" && pageName === "labo")
                            ? "70px"
                            : "",
                      }}
                      className={`${c.headerClassName ?? ""}`}
                      ref={i === 0 ? headRef : undefined}
                    >
                      {tableName === "events" ? (
                        <div>{c.caption}</div>
                      ) : isSelectAllProducts(i) ? (
                        <div className="d-flex">
                         {(tableName !== "production-planning" && tableName !== 'sales-forecast') && <Form.Group className="me-3">
                            <Form.Check
                              type="checkbox"
                              onChange={selectAllProducts}
                            />
                          </Form.Group>}
                          <div style={{width: "100%",padding: tableName === 'labo-order' ? "0 50px": "0px"}}>{c.caption}</div>
                        </div>
                      ) : (
                        !isSelectAllProducts(i) && (
                          <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip(c.caption)}
                          >
                            <div
                              style={
                                tableName === "order-provider"
                                  ? { whiteSpace: "normal" }
                                  : {}
                              }
                            >
                              {c.caption}
                            </div>
                          </OverlayTrigger>
                        )
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody>
              {data?.map((it, ii) => {
                if (it.prediction && SleaveContent) {
                  return (
                    <Sleave
                      key={ii}
                      width={columns.length}
                      isOpen={it.prediction.isOpen}
                      onClose={() => onRowDoubleClick(ii, true)}
                      content={<SleaveContent data={it} />}
                      row={renderRow(it, ii)}
                    />
                  );
                } else {
                  return renderRow(it, ii);
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
})

// CustomTable.propTypes = {
//   columns: PropTypes.array,
// };

export default CustomTable;
